// Recidiviz - a data platform for criminal justice reform
// Copyright (C) 2024 Recidiviz, Inc.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.
// =============================================================================

import logoImg from "@justice-counts/common/assets/jc-logo-vector-new.svg";
import React from "react";
import styled from "styled-components/macro";

const MaintenanceContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
`;

const MaintenancePage = () => {
  return (
    <MaintenanceContainer>
      <img src={logoImg} alt="" />
      Publisher is currently down for maintenance. We apologize for the
      inconvenience and should be up and running shortly.
    </MaintenanceContainer>
  );
};

export default MaintenancePage;
