// Recidiviz - a data platform for criminal justice reform
// Copyright (C) 2024 Recidiviz, Inc.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.
// =============================================================================

import {
  AgencySystem,
  AgencyTeamMember,
  AgencyTeamMemberRole,
} from "@justice-counts/common/types";

export enum Environment {
  LOCAL = "local",
  STAGING = "staging",
  PRODUCTION = "production",
}

export enum Setting {
  USERS = "USERS",
  AGENCIES = "AGENCIES",
}

export type EnvironmentType = `${Environment}`;

export type SettingType = `${Setting}`;

export enum SaveConfirmationTypes {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export type SaveConfirmationType = `${SaveConfirmationTypes}`;

export type ProvisioningProps = {
  selectedIDToEdit?: string | number;
  activeSecondaryModal?: SettingType;
  openSecondaryModal?: () => void;
  closeModal: (resetSearchInput?: boolean) => void;
  secondaryCreatedId?: string | number;
  setSecondaryCreatedId?: (id: string | number) => void;
};

export enum SelectionInputBoxTypes {
  STATE = "STATE",
  COUNTY = "COUNTY",
  SYSTEMS = "SYSTEMS",
  SUPERAGENCY = "SUPERAGENCY",
  CHILD_AGENCIES = "CHILD AGENCIES",
  COPY_CHILD_AGENCIES = "COPY CHILD AGENCIES",
  COPY_AGENCY_METRICS = "COPY AGENCY METRICS",
}

export type SelectionInputBoxType = `${SelectionInputBoxTypes}`;

export type ErrorResponse = { code: string; description: string };

/** Agency Types */

export type Agency = {
  id: string | number;
  name: string;
  systems: AgencySystem[];
  state: StateCodeValue;
  state_code: StateCodeKey | null;
  fips_county_code: FipsCountyCodeKey | null;
  team: AgencyTeamMember[];
  super_agency_id: number | null;
  is_superagency: boolean | null;
  child_agency_ids: number[];
  is_dashboard_enabled: boolean | null;
  created_at: string | null;
  settings: {
    setting_type: string;
    value: string | null;
    source_id: number;
  }[];
};

export type AgencyMetric = {
  key: string;
  name: string;
  sector: string;
};

export type AgencyWithTeamByID = Omit<Agency, "team"> & {
  team: Record<string, AgencyTeamMember[]>;
};

export type AgencyResponse = {
  agencies: Agency[];
  systems: AgencySystem[];
};

export type AgencyMetricResponse = {
  agency: Agency;
  metrics: AgencyMetric[];
};

export const AgencyProvisioningSettings = {
  AGENCY_INFORMATION: "Agency Information",
  TEAM_MEMBERS_ROLES: "Team Members & Roles",
} as const;

export type AgencyProvisioningSetting =
  (typeof AgencyProvisioningSettings)[keyof typeof AgencyProvisioningSettings];

export type AgencyProvisioningUpdates = {
  agency_id?: number | null;
  name: string;
  state_code: StateCodeKey | null;
  fips_county_code: FipsCountyCodeKey | null;
  systems: AgencySystem[];
  is_dashboard_enabled: boolean | null;
  super_agency_id: number | null; // If this is set, then the agency is a child agency belonging to the superagency w/ this ID
  is_superagency: boolean | null;
  child_agency_ids: number[];
  team: AgencyTeamUpdates[];
};

export type AgencyTeamUpdates = {
  user_account_id: number | null;
  role: AgencyTeamMemberRole;
};

/** User Types */

export type User = {
  id: string | number;
  auth0_user_id: string;
  email: string;
  name: string;
  agencies: Agency[];
};

export type UserWithAgenciesByID = Omit<User, "agencies"> & {
  agencies: Record<string, Agency[]>;
};

export type UserResponse = { users: User[] };

export const userRoles = [
  "AGENCY_ADMIN",
  "JUSTICE_COUNTS_ADMIN",
  "CONTRIBUTOR",
  "READ_ONLY",
] as const;

export type UserProvisioningUpdates = {
  user_account_id?: number | string | null;
  name: string;
  email: string;
  agency_ids: number[];
};

export type UserRoleUpdates = {
  [id: number]: AgencyTeamMemberRole;
};

/** Search Feature Types */

export const InteractiveSearchListActions = {
  ADD: "ADD",
  DELETE: "DELETE",
} as const;

export type InteractiveSearchListAction =
  (typeof InteractiveSearchListActions)[keyof typeof InteractiveSearchListActions];

export type InteractiveSearchListUpdateSelections = (
  selection: {
    id: string | number;
    name: string;
    action?: InteractiveSearchListAction;
    email?: string;
    role?: AgencyTeamMemberRole;
  },
  action: InteractiveSearchListAction
) => void;

export type SearchableListItem = {
  id: string | number;
  name: string;
  sectors?: string | string[];
  action?: InteractiveSearchListAction;
  email?: string;
  role?: AgencyTeamMemberRole;
};

export type SearchableSetKeys = Set<number | string>;

export type SearchableEntity =
  | Agency
  | AgencyWithTeamByID
  | User
  | UserWithAgenciesByID
  | AgencyTeamMember
  | SearchableListItem;

export type UserKey = keyof User;

export type AgencyKey = keyof Agency;

export type SearchableListItemKey = keyof SearchableListItem;

export type InteractiveSearchListButtons = {
  label: string;
  onClick: (filteredList?: SearchableListItem[]) => void;
}[];

export type InteractiveSearchListProps = {
  list: SearchableListItem[];
  searchByKeys: SearchableListItemKey[];
  buttons: InteractiveSearchListButtons;
  selections: SearchableSetKeys;
  updateSelections: InteractiveSearchListUpdateSelections;
  boxActionType?: InteractiveSearchListAction;
  metadata?: {
    listBoxLabel: string;
    searchBoxLabel: string;
    listBoxEmptyLabel?: string;
    title?: string;
  };
  isActiveBox?: boolean;
};

/** State and County Code Types */

export const StateCodesToStateNames = {
  us_ak: "Alaska",
  us_al: "Alabama",
  us_ar: "Arkansas",
  us_az: "Arizona",
  us_ca: "California",
  us_co: "Colorado",
  us_ct: "Connecticut",
  us_dc: "District of Columbia", // Counties in DC have a fips "state" code, which is why we need it here
  us_de: "Delaware",
  us_fl: "Florida",
  us_ga: "Georgia",
  us_hi: "Hawaii",
  us_in: "Indiana",
  us_id: "Idaho",
  us_il: "Illinois",
  us_ia: "Iowa",
  us_ks: "Kansas",
  us_ky: "Kentucky",
  us_la: "Louisiana",
  us_ma: "Massachusetts",
  us_md: "Maryland",
  us_me: "Maine",
  us_mi: "Michigan",
  us_mn: "Minnesota",
  us_mo: "Missouri",
  us_ms: "Mississippi",
  us_mt: "Montana",
  us_nc: "North Carolina",
  us_nd: "North Dakota",
  us_ne: "Nebraska",
  us_nh: "New Hampshire",
  us_nj: "New Jersey",
  us_nm: "New Mexico",
  us_nv: "Nevada",
  us_ny: "New York",
  us_oh: "Ohio",
  us_ok: "Oklahoma",
  us_or: "Oregon",
  us_pa: "Pennsylvania",
  us_pr: "Puerto Rico",
  us_ri: "Rhode Island",
  us_sc: "South Carolina",
  us_sd: "South Dakota",
  us_tn: "Tennessee",
  us_tx: "Texas",
  us_ut: "Utah",
  us_va: "Virginia",
  us_vt: "Vermont",
  us_wa: "Washington",
  us_wi: "Wisconsin",
  us_wv: "West Virginia",
  us_wy: "Wyoming",
} as const;

export type StateCodeKey = keyof typeof StateCodesToStateNames;

export type StateCodeValue =
  (typeof StateCodesToStateNames)[keyof typeof StateCodesToStateNames];

/**
 * Values were taken from `fips.csv` in the recidiviz/common/data_sets/fips.csv directory
 * Keys were generated based on `_get_valid_county_names` in recidiviz/common/fips.py
 */
/* eslint-disable camelcase */
export const FipsCountyCodes = {
  us_al_autauga: "Autauga County",
  us_al_baldwin: "Baldwin County",
  us_al_barbour: "Barbour County",
  us_al_bibb: "Bibb County",
  us_al_blount: "Blount County",
  us_al_bullock: "Bullock County",
  us_al_butler: "Butler County",
  us_al_calhoun: "Calhoun County",
  us_al_chambers: "Chambers County",
  us_al_cherokee: "Cherokee County",
  us_al_chilton: "Chilton County",
  us_al_choctaw: "Choctaw County",
  us_al_clarke: "Clarke County",
  us_al_clay: "Clay County",
  us_al_cleburne: "Cleburne County",
  us_al_coffee: "Coffee County",
  us_al_colbert: "Colbert County",
  us_al_conecuh: "Conecuh County",
  us_al_coosa: "Coosa County",
  us_al_covington: "Covington County",
  us_al_crenshaw: "Crenshaw County",
  us_al_cullman: "Cullman County",
  us_al_dale: "Dale County",
  us_al_dallas: "Dallas County",
  us_al_dekalb: "DeKalb County",
  us_al_elmore: "Elmore County",
  us_al_escambia: "Escambia County",
  us_al_etowah: "Etowah County",
  us_al_fayette: "Fayette County",
  us_al_franklin: "Franklin County",
  us_al_geneva: "Geneva County",
  us_al_greene: "Greene County",
  us_al_hale: "Hale County",
  us_al_henry: "Henry County",
  us_al_houston: "Houston County",
  us_al_jackson: "Jackson County",
  us_al_jefferson: "Jefferson County",
  us_al_lamar: "Lamar County",
  us_al_lauderdale: "Lauderdale County",
  us_al_lawrence: "Lawrence County",
  us_al_lee: "Lee County",
  us_al_limestone: "Limestone County",
  us_al_lowndes: "Lowndes County",
  us_al_macon: "Macon County",
  us_al_madison: "Madison County",
  us_al_marengo: "Marengo County",
  us_al_marion: "Marion County",
  us_al_marshall: "Marshall County",
  us_al_mobile: "Mobile County",
  us_al_monroe: "Monroe County",
  us_al_montgomery: "Montgomery County",
  us_al_morgan: "Morgan County",
  us_al_perry: "Perry County",
  us_al_pickens: "Pickens County",
  us_al_pike: "Pike County",
  us_al_randolph: "Randolph County",
  us_al_russell: "Russell County",
  us_al_st_clair: "St. Clair County",
  us_al_shelby: "Shelby County",
  us_al_sumter: "Sumter County",
  us_al_talladega: "Talladega County",
  us_al_tallapoosa: "Tallapoosa County",
  us_al_tuscaloosa: "Tuscaloosa County",
  us_al_walker: "Walker County",
  us_al_washington: "Washington County",
  us_al_wilcox: "Wilcox County",
  us_al_winston: "Winston County",
  us_ak_aleutians_east: "Aleutians East Borough",
  us_ak_aleutians_west: "Aleutians West Census Area",
  us_ak_anchorage: "Anchorage Municipality",
  us_ak_bethel: "Bethel Census Area",
  us_ak_bristol_bay: "Bristol Bay Borough",
  us_ak_denali: "Denali Borough",
  us_ak_dillingham: "Dillingham Census Area",
  us_ak_fairbanks_north_star: "Fairbanks North Star Borough",
  us_ak_haines: "Haines Borough",
  us_ak_hoonah_angoon: "Hoonah-Angoon Census Area",
  us_ak_juneau_city_and: "Juneau City and Borough",
  us_ak_kenai_peninsula: "Kenai Peninsula Borough",
  us_ak_ketchikan_gateway: "Ketchikan Gateway Borough",
  us_ak_kodiak_island: "Kodiak Island Borough",
  us_ak_kusilvak: "Kusilvak Census Area",
  us_ak_lake_and_peninsula: "Lake and Peninsula Borough",
  us_ak_matanuska_susitna: "Matanuska-Susitna Borough",
  us_ak_nome: "Nome Census Area",
  us_ak_north_slope: "North Slope Borough",
  us_ak_northwest_arctic: "Northwest Arctic Borough",
  us_ak_petersburg: "Petersburg Borough",
  us_ak_prince_of_wales_hyder: "Prince of Wales-Hyder Census Area",
  us_ak_sitka_city_and: "Sitka City and Borough",
  us_ak_skagway: "Skagway Municipality",
  us_ak_southeast_fairbanks: "Southeast Fairbanks Census Area",
  us_ak_valdez_cordova: "Valdez-Cordova Census Area",
  us_ak_wade_hampton: "Wade Hampton Census Area",
  us_ak_wrangell_city_and: "Wrangell City and Borough",
  us_ak_yakutat_city_and: "Yakutat City and Borough",
  us_ak_yukon_koyukuk: "Yukon-Koyukuk Census Area",
  us_az_apache: "Apache County",
  us_az_cochise: "Cochise County",
  us_az_coconino: "Coconino County",
  us_az_gila: "Gila County",
  us_az_graham: "Graham County",
  us_az_greenlee: "Greenlee County",
  us_az_la_paz: "La Paz County",
  us_az_maricopa: "Maricopa County",
  us_az_mohave: "Mohave County",
  us_az_navajo: "Navajo County",
  us_az_pima: "Pima County",
  us_az_pinal: "Pinal County",
  us_az_santa_cruz: "Santa Cruz County",
  us_az_yavapai: "Yavapai County",
  us_az_yuma: "Yuma County",
  us_ar_arkansas: "Arkansas County",
  us_ar_ashley: "Ashley County",
  us_ar_baxter: "Baxter County",
  us_ar_benton: "Benton County",
  us_ar_boone: "Boone County",
  us_ar_bradley: "Bradley County",
  us_ar_calhoun: "Calhoun County",
  us_ar_carroll: "Carroll County",
  us_ar_chicot: "Chicot County",
  us_ar_clark: "Clark County",
  us_ar_clay: "Clay County",
  us_ar_cleburne: "Cleburne County",
  us_ar_cleveland: "Cleveland County",
  us_ar_columbia: "Columbia County",
  us_ar_conway: "Conway County",
  us_ar_craighead: "Craighead County",
  us_ar_crawford: "Crawford County",
  us_ar_crittenden: "Crittenden County",
  us_ar_cross: "Cross County",
  us_ar_dallas: "Dallas County",
  us_ar_desha: "Desha County",
  us_ar_drew: "Drew County",
  us_ar_faulkner: "Faulkner County",
  us_ar_franklin: "Franklin County",
  us_ar_fulton: "Fulton County",
  us_ar_garland: "Garland County",
  us_ar_grant: "Grant County",
  us_ar_greene: "Greene County",
  us_ar_hempstead: "Hempstead County",
  us_ar_hot_spring: "Hot Spring County",
  us_ar_howard: "Howard County",
  us_ar_independence: "Independence County",
  us_ar_izard: "Izard County",
  us_ar_jackson: "Jackson County",
  us_ar_jefferson: "Jefferson County",
  us_ar_johnson: "Johnson County",
  us_ar_lafayette: "Lafayette County",
  us_ar_lawrence: "Lawrence County",
  us_ar_lee: "Lee County",
  us_ar_lincoln: "Lincoln County",
  us_ar_little_river: "Little River County",
  us_ar_logan: "Logan County",
  us_ar_lonoke: "Lonoke County",
  us_ar_madison: "Madison County",
  us_ar_marion: "Marion County",
  us_ar_miller: "Miller County",
  us_ar_mississippi: "Mississippi County",
  us_ar_monroe: "Monroe County",
  us_ar_montgomery: "Montgomery County",
  us_ar_nevada: "Nevada County",
  us_ar_newton: "Newton County",
  us_ar_ouachita: "Ouachita County",
  us_ar_perry: "Perry County",
  us_ar_phillips: "Phillips County",
  us_ar_pike: "Pike County",
  us_ar_poinsett: "Poinsett County",
  us_ar_polk: "Polk County",
  us_ar_pope: "Pope County",
  us_ar_prairie: "Prairie County",
  us_ar_pulaski: "Pulaski County",
  us_ar_randolph: "Randolph County",
  us_ar_st_francis: "St. Francis County",
  us_ar_saline: "Saline County",
  us_ar_scott: "Scott County",
  us_ar_searcy: "Searcy County",
  us_ar_sebastian: "Sebastian County",
  us_ar_sevier: "Sevier County",
  us_ar_sharp: "Sharp County",
  us_ar_stone: "Stone County",
  us_ar_union: "Union County",
  us_ar_van_buren: "Van Buren County",
  us_ar_washington: "Washington County",
  us_ar_white: "White County",
  us_ar_woodruff: "Woodruff County",
  us_ar_yell: "Yell County",
  us_ca_alameda: "Alameda County",
  us_ca_alpine: "Alpine County",
  us_ca_amador: "Amador County",
  us_ca_butte: "Butte County",
  us_ca_calaveras: "Calaveras County",
  us_ca_colusa: "Colusa County",
  us_ca_contra_costa: "Contra Costa County",
  us_ca_del_norte: "Del Norte County",
  us_ca_el_dorado: "El Dorado County",
  us_ca_fresno: "Fresno County",
  us_ca_glenn: "Glenn County",
  us_ca_humboldt: "Humboldt County",
  us_ca_imperial: "Imperial County",
  us_ca_inyo: "Inyo County",
  us_ca_kern: "Kern County",
  us_ca_kings: "Kings County",
  us_ca_lake: "Lake County",
  us_ca_lassen: "Lassen County",
  us_ca_los_angeles: "Los Angeles County",
  us_ca_madera: "Madera County",
  us_ca_marin: "Marin County",
  us_ca_mariposa: "Mariposa County",
  us_ca_mendocino: "Mendocino County",
  us_ca_merced: "Merced County",
  us_ca_modoc: "Modoc County",
  us_ca_mono: "Mono County",
  us_ca_monterey: "Monterey County",
  us_ca_napa: "Napa County",
  us_ca_nevada: "Nevada County",
  us_ca_orange: "Orange County",
  us_ca_placer: "Placer County",
  us_ca_plumas: "Plumas County",
  us_ca_riverside: "Riverside County",
  us_ca_sacramento: "Sacramento County",
  us_ca_san_benito: "San Benito County",
  us_ca_san_bernardino: "San Bernardino County",
  us_ca_san_diego: "San Diego County",
  us_ca_san_francisco: "San Francisco County",
  us_ca_san_joaquin: "San Joaquin County",
  us_ca_san_luis_obispo: "San Luis Obispo County",
  us_ca_san_mateo: "San Mateo County",
  us_ca_santa_barbara: "Santa Barbara County",
  us_ca_santa_clara: "Santa Clara County",
  us_ca_santa_cruz: "Santa Cruz County",
  us_ca_shasta: "Shasta County",
  us_ca_sierra: "Sierra County",
  us_ca_siskiyou: "Siskiyou County",
  us_ca_solano: "Solano County",
  us_ca_sonoma: "Sonoma County",
  us_ca_stanislaus: "Stanislaus County",
  us_ca_sutter: "Sutter County",
  us_ca_tehama: "Tehama County",
  us_ca_trinity: "Trinity County",
  us_ca_tulare: "Tulare County",
  us_ca_tuolumne: "Tuolumne County",
  us_ca_ventura: "Ventura County",
  us_ca_yolo: "Yolo County",
  us_ca_yuba: "Yuba County",
  us_co_adams: "Adams County",
  us_co_alamosa: "Alamosa County",
  us_co_arapahoe: "Arapahoe County",
  us_co_archuleta: "Archuleta County",
  us_co_baca: "Baca County",
  us_co_bent: "Bent County",
  us_co_boulder: "Boulder County",
  us_co_broomfield: "Broomfield County",
  us_co_chaffee: "Chaffee County",
  us_co_cheyenne: "Cheyenne County",
  us_co_clear_creek: "Clear Creek County",
  us_co_conejos: "Conejos County",
  us_co_costilla: "Costilla County",
  us_co_crowley: "Crowley County",
  us_co_custer: "Custer County",
  us_co_delta: "Delta County",
  us_co_denver: "Denver County",
  us_co_dolores: "Dolores County",
  us_co_douglas: "Douglas County",
  us_co_eagle: "Eagle County",
  us_co_elbert: "Elbert County",
  us_co_el_paso: "El Paso County",
  us_co_fremont: "Fremont County",
  us_co_garfield: "Garfield County",
  us_co_gilpin: "Gilpin County",
  us_co_grand: "Grand County",
  us_co_gunnison: "Gunnison County",
  us_co_hinsdale: "Hinsdale County",
  us_co_huerfano: "Huerfano County",
  us_co_jackson: "Jackson County",
  us_co_jefferson: "Jefferson County",
  us_co_kiowa: "Kiowa County",
  us_co_kit_carson: "Kit Carson County",
  us_co_lake: "Lake County",
  us_co_la_plata: "La Plata County",
  us_co_larimer: "Larimer County",
  us_co_las_animas: "Las Animas County",
  us_co_lincoln: "Lincoln County",
  us_co_logan: "Logan County",
  us_co_mesa: "Mesa County",
  us_co_mineral: "Mineral County",
  us_co_moffat: "Moffat County",
  us_co_montezuma: "Montezuma County",
  us_co_montrose: "Montrose County",
  us_co_morgan: "Morgan County",
  us_co_otero: "Otero County",
  us_co_ouray: "Ouray County",
  us_co_park: "Park County",
  us_co_phillips: "Phillips County",
  us_co_pitkin: "Pitkin County",
  us_co_prowers: "Prowers County",
  us_co_pueblo: "Pueblo County",
  us_co_rio_blanco: "Rio Blanco County",
  us_co_rio_grande: "Rio Grande County",
  us_co_routt: "Routt County",
  us_co_saguache: "Saguache County",
  us_co_san_juan: "San Juan County",
  us_co_san_miguel: "San Miguel County",
  us_co_sedgwick: "Sedgwick County",
  us_co_summit: "Summit County",
  us_co_teller: "Teller County",
  us_co_washington: "Washington County",
  us_co_weld: "Weld County",
  us_co_yuma: "Yuma County",
  us_ct_fairfield: "Fairfield County",
  us_ct_hartford: "Hartford County",
  us_ct_litchfield: "Litchfield County",
  us_ct_middlesex: "Middlesex County",
  us_ct_new_haven: "New Haven County",
  us_ct_new_london: "New London County",
  us_ct_tolland: "Tolland County",
  us_ct_windham: "Windham County",
  us_de_kent: "Kent County",
  us_de_new_castle: "New Castle County",
  us_de_sussex: "Sussex County",
  us_dc_district_of_columbia: "District of Columbia",
  us_fl_alachua: "Alachua County",
  us_fl_baker: "Baker County",
  us_fl_bay: "Bay County",
  us_fl_bradford: "Bradford County",
  us_fl_brevard: "Brevard County",
  us_fl_broward: "Broward County",
  us_fl_calhoun: "Calhoun County",
  us_fl_charlotte: "Charlotte County",
  us_fl_citrus: "Citrus County",
  us_fl_clay: "Clay County",
  us_fl_collier: "Collier County",
  us_fl_columbia: "Columbia County",
  us_fl_desoto: "DeSoto County",
  us_fl_dixie: "Dixie County",
  us_fl_duval: "Duval County",
  us_fl_escambia: "Escambia County",
  us_fl_flagler: "Flagler County",
  us_fl_franklin: "Franklin County",
  us_fl_gadsden: "Gadsden County",
  us_fl_gilchrist: "Gilchrist County",
  us_fl_glades: "Glades County",
  us_fl_gulf: "Gulf County",
  us_fl_hamilton: "Hamilton County",
  us_fl_hardee: "Hardee County",
  us_fl_hendry: "Hendry County",
  us_fl_hernando: "Hernando County",
  us_fl_highlands: "Highlands County",
  us_fl_hillsborough: "Hillsborough County",
  us_fl_holmes: "Holmes County",
  us_fl_indian_river: "Indian River County",
  us_fl_jackson: "Jackson County",
  us_fl_jefferson: "Jefferson County",
  us_fl_lafayette: "Lafayette County",
  us_fl_lake: "Lake County",
  us_fl_lee: "Lee County",
  us_fl_leon: "Leon County",
  us_fl_levy: "Levy County",
  us_fl_liberty: "Liberty County",
  us_fl_madison: "Madison County",
  us_fl_manatee: "Manatee County",
  us_fl_marion: "Marion County",
  us_fl_martin: "Martin County",
  us_fl_miami_dade: "Miami-Dade County",
  us_fl_monroe: "Monroe County",
  us_fl_nassau: "Nassau County",
  us_fl_okaloosa: "Okaloosa County",
  us_fl_okeechobee: "Okeechobee County",
  us_fl_orange: "Orange County",
  us_fl_osceola: "Osceola County",
  us_fl_palm_beach: "Palm Beach County",
  us_fl_pasco: "Pasco County",
  us_fl_pinellas: "Pinellas County",
  us_fl_polk: "Polk County",
  us_fl_putnam: "Putnam County",
  us_fl_st_johns: "St. Johns County",
  us_fl_st_lucie: "St. Lucie County",
  us_fl_santa_rosa: "Santa Rosa County",
  us_fl_sarasota: "Sarasota County",
  us_fl_seminole: "Seminole County",
  us_fl_sumter: "Sumter County",
  us_fl_suwannee: "Suwannee County",
  us_fl_taylor: "Taylor County",
  us_fl_union: "Union County",
  us_fl_volusia: "Volusia County",
  us_fl_wakulla: "Wakulla County",
  us_fl_walton: "Walton County",
  us_fl_washington: "Washington County",
  us_ga_appling: "Appling County",
  us_ga_atkinson: "Atkinson County",
  us_ga_bacon: "Bacon County",
  us_ga_baker: "Baker County",
  us_ga_baldwin: "Baldwin County",
  us_ga_banks: "Banks County",
  us_ga_barrow: "Barrow County",
  us_ga_bartow: "Bartow County",
  us_ga_ben_hill: "Ben Hill County",
  us_ga_berrien: "Berrien County",
  us_ga_bibb: "Bibb County",
  us_ga_bleckley: "Bleckley County",
  us_ga_brantley: "Brantley County",
  us_ga_brooks: "Brooks County",
  us_ga_bryan: "Bryan County",
  us_ga_bulloch: "Bulloch County",
  us_ga_burke: "Burke County",
  us_ga_butts: "Butts County",
  us_ga_calhoun: "Calhoun County",
  us_ga_camden: "Camden County",
  us_ga_candler: "Candler County",
  us_ga_carroll: "Carroll County",
  us_ga_catoosa: "Catoosa County",
  us_ga_charlton: "Charlton County",
  us_ga_chatham: "Chatham County",
  us_ga_chattahoochee: "Chattahoochee County",
  us_ga_chattooga: "Chattooga County",
  us_ga_cherokee: "Cherokee County",
  us_ga_clarke: "Clarke County",
  us_ga_clay: "Clay County",
  us_ga_clayton: "Clayton County",
  us_ga_clinch: "Clinch County",
  us_ga_cobb: "Cobb County",
  us_ga_coffee: "Coffee County",
  us_ga_colquitt: "Colquitt County",
  us_ga_columbia: "Columbia County",
  us_ga_cook: "Cook County",
  us_ga_coweta: "Coweta County",
  us_ga_crawford: "Crawford County",
  us_ga_crisp: "Crisp County",
  us_ga_dade: "Dade County",
  us_ga_dawson: "Dawson County",
  us_ga_decatur: "Decatur County",
  us_ga_dekalb: "DeKalb County",
  us_ga_dodge: "Dodge County",
  us_ga_dooly: "Dooly County",
  us_ga_dougherty: "Dougherty County",
  us_ga_douglas: "Douglas County",
  us_ga_early: "Early County",
  us_ga_echols: "Echols County",
  us_ga_effingham: "Effingham County",
  us_ga_elbert: "Elbert County",
  us_ga_emanuel: "Emanuel County",
  us_ga_evans: "Evans County",
  us_ga_fannin: "Fannin County",
  us_ga_fayette: "Fayette County",
  us_ga_floyd: "Floyd County",
  us_ga_forsyth: "Forsyth County",
  us_ga_franklin: "Franklin County",
  us_ga_fulton: "Fulton County",
  us_ga_gilmer: "Gilmer County",
  us_ga_glascock: "Glascock County",
  us_ga_glynn: "Glynn County",
  us_ga_gordon: "Gordon County",
  us_ga_grady: "Grady County",
  us_ga_greene: "Greene County",
  us_ga_gwinnett: "Gwinnett County",
  us_ga_habersham: "Habersham County",
  us_ga_hall: "Hall County",
  us_ga_hancock: "Hancock County",
  us_ga_haralson: "Haralson County",
  us_ga_harris: "Harris County",
  us_ga_hart: "Hart County",
  us_ga_heard: "Heard County",
  us_ga_henry: "Henry County",
  us_ga_houston: "Houston County",
  us_ga_irwin: "Irwin County",
  us_ga_jackson: "Jackson County",
  us_ga_jasper: "Jasper County",
  us_ga_jeff_davis: "Jeff Davis County",
  us_ga_jefferson: "Jefferson County",
  us_ga_jenkins: "Jenkins County",
  us_ga_johnson: "Johnson County",
  us_ga_jones: "Jones County",
  us_ga_lamar: "Lamar County",
  us_ga_lanier: "Lanier County",
  us_ga_laurens: "Laurens County",
  us_ga_lee: "Lee County",
  us_ga_liberty: "Liberty County",
  us_ga_lincoln: "Lincoln County",
  us_ga_long: "Long County",
  us_ga_lowndes: "Lowndes County",
  us_ga_lumpkin: "Lumpkin County",
  us_ga_mcduffie: "McDuffie County",
  us_ga_mcintosh: "McIntosh County",
  us_ga_macon: "Macon County",
  us_ga_madison: "Madison County",
  us_ga_marion: "Marion County",
  us_ga_meriwether: "Meriwether County",
  us_ga_miller: "Miller County",
  us_ga_mitchell: "Mitchell County",
  us_ga_monroe: "Monroe County",
  us_ga_montgomery: "Montgomery County",
  us_ga_morgan: "Morgan County",
  us_ga_murray: "Murray County",
  us_ga_muscogee: "Muscogee County",
  us_ga_newton: "Newton County",
  us_ga_oconee: "Oconee County",
  us_ga_oglethorpe: "Oglethorpe County",
  us_ga_paulding: "Paulding County",
  us_ga_peach: "Peach County",
  us_ga_pickens: "Pickens County",
  us_ga_pierce: "Pierce County",
  us_ga_pike: "Pike County",
  us_ga_polk: "Polk County",
  us_ga_pulaski: "Pulaski County",
  us_ga_putnam: "Putnam County",
  us_ga_quitman: "Quitman County",
  us_ga_rabun: "Rabun County",
  us_ga_randolph: "Randolph County",
  us_ga_richmond: "Richmond County",
  us_ga_rockdale: "Rockdale County",
  us_ga_schley: "Schley County",
  us_ga_screven: "Screven County",
  us_ga_seminole: "Seminole County",
  us_ga_spalding: "Spalding County",
  us_ga_stephens: "Stephens County",
  us_ga_stewart: "Stewart County",
  us_ga_sumter: "Sumter County",
  us_ga_talbot: "Talbot County",
  us_ga_taliaferro: "Taliaferro County",
  us_ga_tattnall: "Tattnall County",
  us_ga_taylor: "Taylor County",
  us_ga_telfair: "Telfair County",
  us_ga_terrell: "Terrell County",
  us_ga_thomas: "Thomas County",
  us_ga_tift: "Tift County",
  us_ga_toombs: "Toombs County",
  us_ga_towns: "Towns County",
  us_ga_treutlen: "Treutlen County",
  us_ga_troup: "Troup County",
  us_ga_turner: "Turner County",
  us_ga_twiggs: "Twiggs County",
  us_ga_union: "Union County",
  us_ga_upson: "Upson County",
  us_ga_walker: "Walker County",
  us_ga_walton: "Walton County",
  us_ga_ware: "Ware County",
  us_ga_warren: "Warren County",
  us_ga_washington: "Washington County",
  us_ga_wayne: "Wayne County",
  us_ga_webster: "Webster County",
  us_ga_wheeler: "Wheeler County",
  us_ga_white: "White County",
  us_ga_whitfield: "Whitfield County",
  us_ga_wilcox: "Wilcox County",
  us_ga_wilkes: "Wilkes County",
  us_ga_wilkinson: "Wilkinson County",
  us_ga_worth: "Worth County",
  us_hi_hawaii: "Hawaii County",
  us_hi_honolulu: "Honolulu County",
  us_hi_kalawao: "Kalawao County",
  us_hi_kauai: "Kauai County",
  us_hi_maui: "Maui County",
  us_id_ada: "Ada County",
  us_id_adams: "Adams County",
  us_id_bannock: "Bannock County",
  us_id_bear_lake: "Bear Lake County",
  us_id_benewah: "Benewah County",
  us_id_bingham: "Bingham County",
  us_id_blaine: "Blaine County",
  us_id_boise: "Boise County",
  us_id_bonner: "Bonner County",
  us_id_bonneville: "Bonneville County",
  us_id_boundary: "Boundary County",
  us_id_butte: "Butte County",
  us_id_camas: "Camas County",
  us_id_canyon: "Canyon County",
  us_id_caribou: "Caribou County",
  us_id_cassia: "Cassia County",
  us_id_clark: "Clark County",
  us_id_clearwater: "Clearwater County",
  us_id_custer: "Custer County",
  us_id_elmore: "Elmore County",
  us_id_franklin: "Franklin County",
  us_id_fremont: "Fremont County",
  us_id_gem: "Gem County",
  us_id_gooding: "Gooding County",
  us_id_idaho: "Idaho County",
  us_id_jefferson: "Jefferson County",
  us_id_jerome: "Jerome County",
  us_id_kootenai: "Kootenai County",
  us_id_latah: "Latah County",
  us_id_lemhi: "Lemhi County",
  us_id_lewis: "Lewis County",
  us_id_lincoln: "Lincoln County",
  us_id_madison: "Madison County",
  us_id_minidoka: "Minidoka County",
  us_id_nez_perce: "Nez Perce County",
  us_id_oneida: "Oneida County",
  us_id_owyhee: "Owyhee County",
  us_id_payette: "Payette County",
  us_id_power: "Power County",
  us_id_shoshone: "Shoshone County",
  us_id_teton: "Teton County",
  us_id_twin_falls: "Twin Falls County",
  us_id_valley: "Valley County",
  us_id_washington: "Washington County",
  us_il_adams: "Adams County",
  us_il_alexander: "Alexander County",
  us_il_bond: "Bond County",
  us_il_boone: "Boone County",
  us_il_brown: "Brown County",
  us_il_bureau: "Bureau County",
  us_il_calhoun: "Calhoun County",
  us_il_carroll: "Carroll County",
  us_il_cass: "Cass County",
  us_il_champaign: "Champaign County",
  us_il_christian: "Christian County",
  us_il_clark: "Clark County",
  us_il_clay: "Clay County",
  us_il_clinton: "Clinton County",
  us_il_coles: "Coles County",
  us_il_cook: "Cook County",
  us_il_crawford: "Crawford County",
  us_il_cumberland: "Cumberland County",
  us_il_dekalb: "DeKalb County",
  us_il_de_witt: "De Witt County",
  us_il_douglas: "Douglas County",
  us_il_dupage: "DuPage County",
  us_il_edgar: "Edgar County",
  us_il_edwards: "Edwards County",
  us_il_effingham: "Effingham County",
  us_il_fayette: "Fayette County",
  us_il_ford: "Ford County",
  us_il_franklin: "Franklin County",
  us_il_fulton: "Fulton County",
  us_il_gallatin: "Gallatin County",
  us_il_greene: "Greene County",
  us_il_grundy: "Grundy County",
  us_il_hamilton: "Hamilton County",
  us_il_hancock: "Hancock County",
  us_il_hardin: "Hardin County",
  us_il_henderson: "Henderson County",
  us_il_henry: "Henry County",
  us_il_iroquois: "Iroquois County",
  us_il_jackson: "Jackson County",
  us_il_jasper: "Jasper County",
  us_il_jefferson: "Jefferson County",
  us_il_jersey: "Jersey County",
  us_il_jo_daviess: "Jo Daviess County",
  us_il_johnson: "Johnson County",
  us_il_kane: "Kane County",
  us_il_kankakee: "Kankakee County",
  us_il_kendall: "Kendall County",
  us_il_knox: "Knox County",
  us_il_lake: "Lake County",
  us_il_lasalle: "LaSalle County",
  us_il_lawrence: "Lawrence County",
  us_il_lee: "Lee County",
  us_il_livingston: "Livingston County",
  us_il_logan: "Logan County",
  us_il_mcdonough: "McDonough County",
  us_il_mchenry: "McHenry County",
  us_il_mclean: "McLean County",
  us_il_macon: "Macon County",
  us_il_macoupin: "Macoupin County",
  us_il_madison: "Madison County",
  us_il_marion: "Marion County",
  us_il_marshall: "Marshall County",
  us_il_mason: "Mason County",
  us_il_massac: "Massac County",
  us_il_menard: "Menard County",
  us_il_mercer: "Mercer County",
  us_il_monroe: "Monroe County",
  us_il_montgomery: "Montgomery County",
  us_il_morgan: "Morgan County",
  us_il_moultrie: "Moultrie County",
  us_il_ogle: "Ogle County",
  us_il_peoria: "Peoria County",
  us_il_perry: "Perry County",
  us_il_piatt: "Piatt County",
  us_il_pike: "Pike County",
  us_il_pope: "Pope County",
  us_il_pulaski: "Pulaski County",
  us_il_putnam: "Putnam County",
  us_il_randolph: "Randolph County",
  us_il_richland: "Richland County",
  us_il_rock_island: "Rock Island County",
  us_il_st_clair: "St. Clair County",
  us_il_saline: "Saline County",
  us_il_sangamon: "Sangamon County",
  us_il_schuyler: "Schuyler County",
  us_il_scott: "Scott County",
  us_il_shelby: "Shelby County",
  us_il_stark: "Stark County",
  us_il_stephenson: "Stephenson County",
  us_il_tazewell: "Tazewell County",
  us_il_union: "Union County",
  us_il_vermilion: "Vermilion County",
  us_il_wabash: "Wabash County",
  us_il_warren: "Warren County",
  us_il_washington: "Washington County",
  us_il_wayne: "Wayne County",
  us_il_white: "White County",
  us_il_whiteside: "Whiteside County",
  us_il_will: "Will County",
  us_il_williamson: "Williamson County",
  us_il_winnebago: "Winnebago County",
  us_il_woodford: "Woodford County",
  us_in_adams: "Adams County",
  us_in_allen: "Allen County",
  us_in_bartholomew: "Bartholomew County",
  us_in_benton: "Benton County",
  us_in_blackford: "Blackford County",
  us_in_boone: "Boone County",
  us_in_brown: "Brown County",
  us_in_carroll: "Carroll County",
  us_in_cass: "Cass County",
  us_in_clark: "Clark County",
  us_in_clay: "Clay County",
  us_in_clinton: "Clinton County",
  us_in_crawford: "Crawford County",
  us_in_daviess: "Daviess County",
  us_in_dearborn: "Dearborn County",
  us_in_decatur: "Decatur County",
  us_in_dekalb: "DeKalb County",
  us_in_delaware: "Delaware County",
  us_in_dubois: "Dubois County",
  us_in_elkhart: "Elkhart County",
  us_in_fayette: "Fayette County",
  us_in_floyd: "Floyd County",
  us_in_fountain: "Fountain County",
  us_in_franklin: "Franklin County",
  us_in_fulton: "Fulton County",
  us_in_gibson: "Gibson County",
  us_in_grant: "Grant County",
  us_in_greene: "Greene County",
  us_in_hamilton: "Hamilton County",
  us_in_hancock: "Hancock County",
  us_in_harrison: "Harrison County",
  us_in_hendricks: "Hendricks County",
  us_in_henry: "Henry County",
  us_in_howard: "Howard County",
  us_in_huntington: "Huntington County",
  us_in_jackson: "Jackson County",
  us_in_jasper: "Jasper County",
  us_in_jay: "Jay County",
  us_in_jefferson: "Jefferson County",
  us_in_jennings: "Jennings County",
  us_in_johnson: "Johnson County",
  us_in_knox: "Knox County",
  us_in_kosciusko: "Kosciusko County",
  us_in_lagrange: "LaGrange County",
  us_in_lake: "Lake County",
  us_in_laporte: "LaPorte County",
  us_in_lawrence: "Lawrence County",
  us_in_madison: "Madison County",
  us_in_marion: "Marion County",
  us_in_marshall: "Marshall County",
  us_in_martin: "Martin County",
  us_in_miami: "Miami County",
  us_in_monroe: "Monroe County",
  us_in_montgomery: "Montgomery County",
  us_in_morgan: "Morgan County",
  us_in_newton: "Newton County",
  us_in_noble: "Noble County",
  us_in_ohio: "Ohio County",
  us_in_orange: "Orange County",
  us_in_owen: "Owen County",
  us_in_parke: "Parke County",
  us_in_perry: "Perry County",
  us_in_pike: "Pike County",
  us_in_porter: "Porter County",
  us_in_posey: "Posey County",
  us_in_pulaski: "Pulaski County",
  us_in_putnam: "Putnam County",
  us_in_randolph: "Randolph County",
  us_in_ripley: "Ripley County",
  us_in_rush: "Rush County",
  us_in_st_joseph: "St. Joseph County",
  us_in_scott: "Scott County",
  us_in_shelby: "Shelby County",
  us_in_spencer: "Spencer County",
  us_in_starke: "Starke County",
  us_in_steuben: "Steuben County",
  us_in_sullivan: "Sullivan County",
  us_in_switzerland: "Switzerland County",
  us_in_tippecanoe: "Tippecanoe County",
  us_in_tipton: "Tipton County",
  us_in_union: "Union County",
  us_in_vanderburgh: "Vanderburgh County",
  us_in_vermillion: "Vermillion County",
  us_in_vigo: "Vigo County",
  us_in_wabash: "Wabash County",
  us_in_warren: "Warren County",
  us_in_warrick: "Warrick County",
  us_in_washington: "Washington County",
  us_in_wayne: "Wayne County",
  us_in_wells: "Wells County",
  us_in_white: "White County",
  us_in_whitley: "Whitley County",
  us_ia_adair: "Adair County",
  us_ia_adams: "Adams County",
  us_ia_allamakee: "Allamakee County",
  us_ia_appanoose: "Appanoose County",
  us_ia_audubon: "Audubon County",
  us_ia_benton: "Benton County",
  us_ia_black_hawk: "Black Hawk County",
  us_ia_boone: "Boone County",
  us_ia_bremer: "Bremer County",
  us_ia_buchanan: "Buchanan County",
  us_ia_buena_vista: "Buena Vista County",
  us_ia_butler: "Butler County",
  us_ia_calhoun: "Calhoun County",
  us_ia_carroll: "Carroll County",
  us_ia_cass: "Cass County",
  us_ia_cedar: "Cedar County",
  us_ia_cerro_gordo: "Cerro Gordo County",
  us_ia_cherokee: "Cherokee County",
  us_ia_chickasaw: "Chickasaw County",
  us_ia_clarke: "Clarke County",
  us_ia_clay: "Clay County",
  us_ia_clayton: "Clayton County",
  us_ia_clinton: "Clinton County",
  us_ia_crawford: "Crawford County",
  us_ia_dallas: "Dallas County",
  us_ia_davis: "Davis County",
  us_ia_decatur: "Decatur County",
  us_ia_delaware: "Delaware County",
  us_ia_des_moines: "Des Moines County",
  us_ia_dickinson: "Dickinson County",
  us_ia_dubuque: "Dubuque County",
  us_ia_emmet: "Emmet County",
  us_ia_fayette: "Fayette County",
  us_ia_floyd: "Floyd County",
  us_ia_franklin: "Franklin County",
  us_ia_fremont: "Fremont County",
  us_ia_greene: "Greene County",
  us_ia_grundy: "Grundy County",
  us_ia_guthrie: "Guthrie County",
  us_ia_hamilton: "Hamilton County",
  us_ia_hancock: "Hancock County",
  us_ia_hardin: "Hardin County",
  us_ia_harrison: "Harrison County",
  us_ia_henry: "Henry County",
  us_ia_howard: "Howard County",
  us_ia_humboldt: "Humboldt County",
  us_ia_ida: "Ida County",
  us_ia_iowa: "Iowa County",
  us_ia_jackson: "Jackson County",
  us_ia_jasper: "Jasper County",
  us_ia_jefferson: "Jefferson County",
  us_ia_johnson: "Johnson County",
  us_ia_jones: "Jones County",
  us_ia_keokuk: "Keokuk County",
  us_ia_kossuth: "Kossuth County",
  us_ia_lee: "Lee County",
  us_ia_linn: "Linn County",
  us_ia_louisa: "Louisa County",
  us_ia_lucas: "Lucas County",
  us_ia_lyon: "Lyon County",
  us_ia_madison: "Madison County",
  us_ia_mahaska: "Mahaska County",
  us_ia_marion: "Marion County",
  us_ia_marshall: "Marshall County",
  us_ia_mills: "Mills County",
  us_ia_mitchell: "Mitchell County",
  us_ia_monona: "Monona County",
  us_ia_monroe: "Monroe County",
  us_ia_montgomery: "Montgomery County",
  us_ia_muscatine: "Muscatine County",
  us_ia_obrien: "O'Brien County",
  us_ia_osceola: "Osceola County",
  us_ia_page: "Page County",
  us_ia_palo_alto: "Palo Alto County",
  us_ia_plymouth: "Plymouth County",
  us_ia_pocahontas: "Pocahontas County",
  us_ia_polk: "Polk County",
  us_ia_pottawattamie: "Pottawattamie County",
  us_ia_poweshiek: "Poweshiek County",
  us_ia_ringgold: "Ringgold County",
  us_ia_sac: "Sac County",
  us_ia_scott: "Scott County",
  us_ia_shelby: "Shelby County",
  us_ia_sioux: "Sioux County",
  us_ia_story: "Story County",
  us_ia_tama: "Tama County",
  us_ia_taylor: "Taylor County",
  us_ia_union: "Union County",
  us_ia_van_buren: "Van Buren County",
  us_ia_wapello: "Wapello County",
  us_ia_warren: "Warren County",
  us_ia_washington: "Washington County",
  us_ia_wayne: "Wayne County",
  us_ia_webster: "Webster County",
  us_ia_winnebago: "Winnebago County",
  us_ia_winneshiek: "Winneshiek County",
  us_ia_woodbury: "Woodbury County",
  us_ia_worth: "Worth County",
  us_ia_wright: "Wright County",
  us_ks_allen: "Allen County",
  us_ks_anderson: "Anderson County",
  us_ks_atchison: "Atchison County",
  us_ks_barber: "Barber County",
  us_ks_barton: "Barton County",
  us_ks_bourbon: "Bourbon County",
  us_ks_brown: "Brown County",
  us_ks_butler: "Butler County",
  us_ks_chase: "Chase County",
  us_ks_chautauqua: "Chautauqua County",
  us_ks_cherokee: "Cherokee County",
  us_ks_cheyenne: "Cheyenne County",
  us_ks_clark: "Clark County",
  us_ks_clay: "Clay County",
  us_ks_cloud: "Cloud County",
  us_ks_coffey: "Coffey County",
  us_ks_comanche: "Comanche County",
  us_ks_cowley: "Cowley County",
  us_ks_crawford: "Crawford County",
  us_ks_decatur: "Decatur County",
  us_ks_dickinson: "Dickinson County",
  us_ks_doniphan: "Doniphan County",
  us_ks_douglas: "Douglas County",
  us_ks_edwards: "Edwards County",
  us_ks_elk: "Elk County",
  us_ks_ellis: "Ellis County",
  us_ks_ellsworth: "Ellsworth County",
  us_ks_finney: "Finney County",
  us_ks_ford: "Ford County",
  us_ks_franklin: "Franklin County",
  us_ks_geary: "Geary County",
  us_ks_gove: "Gove County",
  us_ks_graham: "Graham County",
  us_ks_grant: "Grant County",
  us_ks_gray: "Gray County",
  us_ks_greeley: "Greeley County",
  us_ks_greenwood: "Greenwood County",
  us_ks_hamilton: "Hamilton County",
  us_ks_harper: "Harper County",
  us_ks_harvey: "Harvey County",
  us_ks_haskell: "Haskell County",
  us_ks_hodgeman: "Hodgeman County",
  us_ks_jackson: "Jackson County",
  us_ks_jefferson: "Jefferson County",
  us_ks_jewell: "Jewell County",
  us_ks_johnson: "Johnson County",
  us_ks_kearny: "Kearny County",
  us_ks_kingman: "Kingman County",
  us_ks_kiowa: "Kiowa County",
  us_ks_labette: "Labette County",
  us_ks_lane: "Lane County",
  us_ks_leavenworth: "Leavenworth County",
  us_ks_lincoln: "Lincoln County",
  us_ks_linn: "Linn County",
  us_ks_logan: "Logan County",
  us_ks_lyon: "Lyon County",
  us_ks_mcpherson: "McPherson County",
  us_ks_marion: "Marion County",
  us_ks_marshall: "Marshall County",
  us_ks_meade: "Meade County",
  us_ks_miami: "Miami County",
  us_ks_mitchell: "Mitchell County",
  us_ks_montgomery: "Montgomery County",
  us_ks_morris: "Morris County",
  us_ks_morton: "Morton County",
  us_ks_nemaha: "Nemaha County",
  us_ks_neosho: "Neosho County",
  us_ks_ness: "Ness County",
  us_ks_norton: "Norton County",
  us_ks_osage: "Osage County",
  us_ks_osborne: "Osborne County",
  us_ks_ottawa: "Ottawa County",
  us_ks_pawnee: "Pawnee County",
  us_ks_phillips: "Phillips County",
  us_ks_pottawatomie: "Pottawatomie County",
  us_ks_pratt: "Pratt County",
  us_ks_rawlins: "Rawlins County",
  us_ks_reno: "Reno County",
  us_ks_republic: "Republic County",
  us_ks_rice: "Rice County",
  us_ks_riley: "Riley County",
  us_ks_rooks: "Rooks County",
  us_ks_rush: "Rush County",
  us_ks_russell: "Russell County",
  us_ks_saline: "Saline County",
  us_ks_scott: "Scott County",
  us_ks_sedgwick: "Sedgwick County",
  us_ks_seward: "Seward County",
  us_ks_shawnee: "Shawnee County",
  us_ks_sheridan: "Sheridan County",
  us_ks_sherman: "Sherman County",
  us_ks_smith: "Smith County",
  us_ks_stafford: "Stafford County",
  us_ks_stanton: "Stanton County",
  us_ks_stevens: "Stevens County",
  us_ks_sumner: "Sumner County",
  us_ks_thomas: "Thomas County",
  us_ks_trego: "Trego County",
  us_ks_wabaunsee: "Wabaunsee County",
  us_ks_wallace: "Wallace County",
  us_ks_washington: "Washington County",
  us_ks_wichita: "Wichita County",
  us_ks_wilson: "Wilson County",
  us_ks_woodson: "Woodson County",
  us_ks_wyandotte: "Wyandotte County",
  us_ky_adair: "Adair County",
  us_ky_allen: "Allen County",
  us_ky_anderson: "Anderson County",
  us_ky_ballard: "Ballard County",
  us_ky_barren: "Barren County",
  us_ky_bath: "Bath County",
  us_ky_bell: "Bell County",
  us_ky_boone: "Boone County",
  us_ky_bourbon: "Bourbon County",
  us_ky_boyd: "Boyd County",
  us_ky_boyle: "Boyle County",
  us_ky_bracken: "Bracken County",
  us_ky_breathitt: "Breathitt County",
  us_ky_breckinridge: "Breckinridge County",
  us_ky_bullitt: "Bullitt County",
  us_ky_butler: "Butler County",
  us_ky_caldwell: "Caldwell County",
  us_ky_calloway: "Calloway County",
  us_ky_campbell: "Campbell County",
  us_ky_carlisle: "Carlisle County",
  us_ky_carroll: "Carroll County",
  us_ky_carter: "Carter County",
  us_ky_casey: "Casey County",
  us_ky_christian: "Christian County",
  us_ky_clark: "Clark County",
  us_ky_clay: "Clay County",
  us_ky_clinton: "Clinton County",
  us_ky_crittenden: "Crittenden County",
  us_ky_cumberland: "Cumberland County",
  us_ky_daviess: "Daviess County",
  us_ky_edmonson: "Edmonson County",
  us_ky_elliott: "Elliott County",
  us_ky_estill: "Estill County",
  us_ky_fayette: "Fayette County",
  us_ky_fleming: "Fleming County",
  us_ky_floyd: "Floyd County",
  us_ky_franklin: "Franklin County",
  us_ky_fulton: "Fulton County",
  us_ky_gallatin: "Gallatin County",
  us_ky_garrard: "Garrard County",
  us_ky_grant: "Grant County",
  us_ky_graves: "Graves County",
  us_ky_grayson: "Grayson County",
  us_ky_green: "Green County",
  us_ky_greenup: "Greenup County",
  us_ky_hancock: "Hancock County",
  us_ky_hardin: "Hardin County",
  us_ky_harlan: "Harlan County",
  us_ky_harrison: "Harrison County",
  us_ky_hart: "Hart County",
  us_ky_henderson: "Henderson County",
  us_ky_henry: "Henry County",
  us_ky_hickman: "Hickman County",
  us_ky_hopkins: "Hopkins County",
  us_ky_jackson: "Jackson County",
  us_ky_jefferson: "Jefferson County",
  us_ky_jessamine: "Jessamine County",
  us_ky_johnson: "Johnson County",
  us_ky_kenton: "Kenton County",
  us_ky_knott: "Knott County",
  us_ky_knox: "Knox County",
  us_ky_larue: "Larue County",
  us_ky_laurel: "Laurel County",
  us_ky_lawrence: "Lawrence County",
  us_ky_lee: "Lee County",
  us_ky_leslie: "Leslie County",
  us_ky_letcher: "Letcher County",
  us_ky_lewis: "Lewis County",
  us_ky_lincoln: "Lincoln County",
  us_ky_livingston: "Livingston County",
  us_ky_logan: "Logan County",
  us_ky_lyon: "Lyon County",
  us_ky_mccracken: "McCracken County",
  us_ky_mccreary: "McCreary County",
  us_ky_mclean: "McLean County",
  us_ky_madison: "Madison County",
  us_ky_magoffin: "Magoffin County",
  us_ky_marion: "Marion County",
  us_ky_marshall: "Marshall County",
  us_ky_martin: "Martin County",
  us_ky_mason: "Mason County",
  us_ky_meade: "Meade County",
  us_ky_menifee: "Menifee County",
  us_ky_mercer: "Mercer County",
  us_ky_metcalfe: "Metcalfe County",
  us_ky_monroe: "Monroe County",
  us_ky_montgomery: "Montgomery County",
  us_ky_morgan: "Morgan County",
  us_ky_muhlenberg: "Muhlenberg County",
  us_ky_nelson: "Nelson County",
  us_ky_nicholas: "Nicholas County",
  us_ky_ohio: "Ohio County",
  us_ky_oldham: "Oldham County",
  us_ky_owen: "Owen County",
  us_ky_owsley: "Owsley County",
  us_ky_pendleton: "Pendleton County",
  us_ky_perry: "Perry County",
  us_ky_pike: "Pike County",
  us_ky_powell: "Powell County",
  us_ky_pulaski: "Pulaski County",
  us_ky_robertson: "Robertson County",
  us_ky_rockcastle: "Rockcastle County",
  us_ky_rowan: "Rowan County",
  us_ky_russell: "Russell County",
  us_ky_scott: "Scott County",
  us_ky_shelby: "Shelby County",
  us_ky_simpson: "Simpson County",
  us_ky_spencer: "Spencer County",
  us_ky_taylor: "Taylor County",
  us_ky_todd: "Todd County",
  us_ky_trigg: "Trigg County",
  us_ky_trimble: "Trimble County",
  us_ky_union: "Union County",
  us_ky_warren: "Warren County",
  us_ky_washington: "Washington County",
  us_ky_wayne: "Wayne County",
  us_ky_webster: "Webster County",
  us_ky_whitley: "Whitley County",
  us_ky_wolfe: "Wolfe County",
  us_ky_woodford: "Woodford County",
  us_la_acadia: "Acadia Parish",
  us_la_allen: "Allen Parish",
  us_la_ascension: "Ascension Parish",
  us_la_assumption: "Assumption Parish",
  us_la_avoyelles: "Avoyelles Parish",
  us_la_beauregard: "Beauregard Parish",
  us_la_bienville: "Bienville Parish",
  us_la_bossier: "Bossier Parish",
  us_la_caddo: "Caddo Parish",
  us_la_calcasieu: "Calcasieu Parish",
  us_la_caldwell: "Caldwell Parish",
  us_la_cameron: "Cameron Parish",
  us_la_catahoula: "Catahoula Parish",
  us_la_claiborne: "Claiborne Parish",
  us_la_concordia: "Concordia Parish",
  us_la_de_soto: "De Soto Parish",
  us_la_east_baton_rouge: "East Baton Rouge Parish",
  us_la_east_carroll: "East Carroll Parish",
  us_la_east_feliciana: "East Feliciana Parish",
  us_la_evangeline: "Evangeline Parish",
  us_la_franklin: "Franklin Parish",
  us_la_grant: "Grant Parish",
  us_la_iberia: "Iberia Parish",
  us_la_iberville: "Iberville Parish",
  us_la_jackson: "Jackson Parish",
  us_la_jefferson: "Jefferson Parish",
  us_la_jefferson_davis: "Jefferson Davis Parish",
  us_la_lafayette: "Lafayette Parish",
  us_la_lafourche: "Lafourche Parish",
  us_la_lasalle: "LaSalle Parish",
  us_la_lincoln: "Lincoln Parish",
  us_la_livingston: "Livingston Parish",
  us_la_madison: "Madison Parish",
  us_la_morehouse: "Morehouse Parish",
  us_la_natchitoches: "Natchitoches Parish",
  us_la_orleans: "Orleans Parish",
  us_la_ouachita: "Ouachita Parish",
  us_la_plaquemines: "Plaquemines Parish",
  us_la_pointe_coupee: "Pointe Coupee Parish",
  us_la_rapides: "Rapides Parish",
  us_la_red_river: "Red River Parish",
  us_la_richland: "Richland Parish",
  us_la_sabine: "Sabine Parish",
  us_la_st_bernard: "St. Bernard Parish",
  us_la_st_charles: "St. Charles Parish",
  us_la_st_helena: "St. Helena Parish",
  us_la_st_james: "St. James Parish",
  us_la_st_john_the_baptist: "St. John the Baptist Parish",
  us_la_st_landry: "St. Landry Parish",
  us_la_st_martin: "St. Martin Parish",
  us_la_st_mary: "St. Mary Parish",
  us_la_st_tammany: "St. Tammany Parish",
  us_la_tangipahoa: "Tangipahoa Parish",
  us_la_tensas: "Tensas Parish",
  us_la_terrebonne: "Terrebonne Parish",
  us_la_union: "Union Parish",
  us_la_vermilion: "Vermilion Parish",
  us_la_vernon: "Vernon Parish",
  us_la_washington: "Washington Parish",
  us_la_webster: "Webster Parish",
  us_la_west_baton_rouge: "West Baton Rouge Parish",
  us_la_west_carroll: "West Carroll Parish",
  us_la_west_feliciana: "West Feliciana Parish",
  us_la_winn: "Winn Parish",
  us_me_androscoggin: "Androscoggin County",
  us_me_aroostook: "Aroostook County",
  us_me_cumberland: "Cumberland County",
  us_me_franklin: "Franklin County",
  us_me_hancock: "Hancock County",
  us_me_kennebec: "Kennebec County",
  us_me_knox: "Knox County",
  us_me_lincoln: "Lincoln County",
  us_me_oxford: "Oxford County",
  us_me_penobscot: "Penobscot County",
  us_me_piscataquis: "Piscataquis County",
  us_me_sagadahoc: "Sagadahoc County",
  us_me_somerset: "Somerset County",
  us_me_waldo: "Waldo County",
  us_me_washington: "Washington County",
  us_me_york: "York County",
  us_md_allegany: "Allegany County",
  us_md_anne_arundel: "Anne Arundel County",
  us_md_baltimore: "Baltimore County",
  us_md_calvert: "Calvert County",
  us_md_caroline: "Caroline County",
  us_md_carroll: "Carroll County",
  us_md_cecil: "Cecil County",
  us_md_charles: "Charles County",
  us_md_dorchester: "Dorchester County",
  us_md_frederick: "Frederick County",
  us_md_garrett: "Garrett County",
  us_md_harford: "Harford County",
  us_md_howard: "Howard County",
  us_md_kent: "Kent County",
  us_md_montgomery: "Montgomery County",
  us_md_prince_georges: "Prince George's County",
  us_md_queen_annes: "Queen Anne's County",
  us_md_st_marys: "St. Mary's County",
  us_md_somerset: "Somerset County",
  us_md_talbot: "Talbot County",
  us_md_washington: "Washington County",
  us_md_wicomico: "Wicomico County",
  us_md_worcester: "Worcester County",
  us_md_baltimore_city: "Baltimore city",
  us_ma_barnstable: "Barnstable County",
  us_ma_berkshire: "Berkshire County",
  us_ma_bristol: "Bristol County",
  us_ma_dukes: "Dukes County",
  us_ma_essex: "Essex County",
  us_ma_franklin: "Franklin County",
  us_ma_hampden: "Hampden County",
  us_ma_hampshire: "Hampshire County",
  us_ma_middlesex: "Middlesex County",
  us_ma_nantucket: "Nantucket County",
  us_ma_norfolk: "Norfolk County",
  us_ma_plymouth: "Plymouth County",
  us_ma_suffolk: "Suffolk County",
  us_ma_worcester: "Worcester County",
  us_mi_alcona: "Alcona County",
  us_mi_alger: "Alger County",
  us_mi_allegan: "Allegan County",
  us_mi_alpena: "Alpena County",
  us_mi_antrim: "Antrim County",
  us_mi_arenac: "Arenac County",
  us_mi_baraga: "Baraga County",
  us_mi_barry: "Barry County",
  us_mi_bay: "Bay County",
  us_mi_benzie: "Benzie County",
  us_mi_berrien: "Berrien County",
  us_mi_branch: "Branch County",
  us_mi_calhoun: "Calhoun County",
  us_mi_cass: "Cass County",
  us_mi_charlevoix: "Charlevoix County",
  us_mi_cheboygan: "Cheboygan County",
  us_mi_chippewa: "Chippewa County",
  us_mi_clare: "Clare County",
  us_mi_clinton: "Clinton County",
  us_mi_crawford: "Crawford County",
  us_mi_delta: "Delta County",
  us_mi_dickinson: "Dickinson County",
  us_mi_eaton: "Eaton County",
  us_mi_emmet: "Emmet County",
  us_mi_genesee: "Genesee County",
  us_mi_gladwin: "Gladwin County",
  us_mi_gogebic: "Gogebic County",
  us_mi_grand_traverse: "Grand Traverse County",
  us_mi_gratiot: "Gratiot County",
  us_mi_hillsdale: "Hillsdale County",
  us_mi_houghton: "Houghton County",
  us_mi_huron: "Huron County",
  us_mi_ingham: "Ingham County",
  us_mi_ionia: "Ionia County",
  us_mi_iosco: "Iosco County",
  us_mi_iron: "Iron County",
  us_mi_isabella: "Isabella County",
  us_mi_jackson: "Jackson County",
  us_mi_kalamazoo: "Kalamazoo County",
  us_mi_kalkaska: "Kalkaska County",
  us_mi_kent: "Kent County",
  us_mi_keweenaw: "Keweenaw County",
  us_mi_lake: "Lake County",
  us_mi_lapeer: "Lapeer County",
  us_mi_leelanau: "Leelanau County",
  us_mi_lenawee: "Lenawee County",
  us_mi_livingston: "Livingston County",
  us_mi_luce: "Luce County",
  us_mi_mackinac: "Mackinac County",
  us_mi_macomb: "Macomb County",
  us_mi_manistee: "Manistee County",
  us_mi_marquette: "Marquette County",
  us_mi_mason: "Mason County",
  us_mi_mecosta: "Mecosta County",
  us_mi_menominee: "Menominee County",
  us_mi_midland: "Midland County",
  us_mi_missaukee: "Missaukee County",
  us_mi_monroe: "Monroe County",
  us_mi_montcalm: "Montcalm County",
  us_mi_montmorency: "Montmorency County",
  us_mi_muskegon: "Muskegon County",
  us_mi_newaygo: "Newaygo County",
  us_mi_oakland: "Oakland County",
  us_mi_oceana: "Oceana County",
  us_mi_ogemaw: "Ogemaw County",
  us_mi_ontonagon: "Ontonagon County",
  us_mi_osceola: "Osceola County",
  us_mi_oscoda: "Oscoda County",
  us_mi_otsego: "Otsego County",
  us_mi_ottawa: "Ottawa County",
  us_mi_presque_isle: "Presque Isle County",
  us_mi_roscommon: "Roscommon County",
  us_mi_saginaw: "Saginaw County",
  us_mi_st_clair: "St. Clair County",
  us_mi_st_joseph: "St. Joseph County",
  us_mi_sanilac: "Sanilac County",
  us_mi_schoolcraft: "Schoolcraft County",
  us_mi_shiawassee: "Shiawassee County",
  us_mi_tuscola: "Tuscola County",
  us_mi_van_buren: "Van Buren County",
  us_mi_washtenaw: "Washtenaw County",
  us_mi_wayne: "Wayne County",
  us_mi_wexford: "Wexford County",
  us_mn_aitkin: "Aitkin County",
  us_mn_anoka: "Anoka County",
  us_mn_becker: "Becker County",
  us_mn_beltrami: "Beltrami County",
  us_mn_benton: "Benton County",
  us_mn_big_stone: "Big Stone County",
  us_mn_blue_earth: "Blue Earth County",
  us_mn_brown: "Brown County",
  us_mn_carlton: "Carlton County",
  us_mn_carver: "Carver County",
  us_mn_cass: "Cass County",
  us_mn_chippewa: "Chippewa County",
  us_mn_chisago: "Chisago County",
  us_mn_clay: "Clay County",
  us_mn_clearwater: "Clearwater County",
  us_mn_cook: "Cook County",
  us_mn_cottonwood: "Cottonwood County",
  us_mn_crow_wing: "Crow Wing County",
  us_mn_dakota: "Dakota County",
  us_mn_dodge: "Dodge County",
  us_mn_douglas: "Douglas County",
  us_mn_faribault: "Faribault County",
  us_mn_fillmore: "Fillmore County",
  us_mn_freeborn: "Freeborn County",
  us_mn_goodhue: "Goodhue County",
  us_mn_grant: "Grant County",
  us_mn_hennepin: "Hennepin County",
  us_mn_houston: "Houston County",
  us_mn_hubbard: "Hubbard County",
  us_mn_isanti: "Isanti County",
  us_mn_itasca: "Itasca County",
  us_mn_jackson: "Jackson County",
  us_mn_kanabec: "Kanabec County",
  us_mn_kandiyohi: "Kandiyohi County",
  us_mn_kittson: "Kittson County",
  us_mn_koochiching: "Koochiching County",
  us_mn_lac_qui_parle: "Lac qui Parle County",
  us_mn_lake: "Lake County",
  us_mn_lake_of_the_woods: "Lake of the Woods County",
  us_mn_le_sueur: "Le Sueur County",
  us_mn_lincoln: "Lincoln County",
  us_mn_lyon: "Lyon County",
  us_mn_mcleod: "McLeod County",
  us_mn_mahnomen: "Mahnomen County",
  us_mn_marshall: "Marshall County",
  us_mn_martin: "Martin County",
  us_mn_meeker: "Meeker County",
  us_mn_mille_lacs: "Mille Lacs County",
  us_mn_morrison: "Morrison County",
  us_mn_mower: "Mower County",
  us_mn_murray: "Murray County",
  us_mn_nicollet: "Nicollet County",
  us_mn_nobles: "Nobles County",
  us_mn_norman: "Norman County",
  us_mn_olmsted: "Olmsted County",
  us_mn_otter_tail: "Otter Tail County",
  us_mn_pennington: "Pennington County",
  us_mn_pine: "Pine County",
  us_mn_pipestone: "Pipestone County",
  us_mn_polk: "Polk County",
  us_mn_pope: "Pope County",
  us_mn_ramsey: "Ramsey County",
  us_mn_red_lake: "Red Lake County",
  us_mn_redwood: "Redwood County",
  us_mn_renville: "Renville County",
  us_mn_rice: "Rice County",
  us_mn_rock: "Rock County",
  us_mn_roseau: "Roseau County",
  us_mn_st_louis: "St. Louis County",
  us_mn_scott: "Scott County",
  us_mn_sherburne: "Sherburne County",
  us_mn_sibley: "Sibley County",
  us_mn_stearns: "Stearns County",
  us_mn_steele: "Steele County",
  us_mn_stevens: "Stevens County",
  us_mn_swift: "Swift County",
  us_mn_todd: "Todd County",
  us_mn_traverse: "Traverse County",
  us_mn_wabasha: "Wabasha County",
  us_mn_wadena: "Wadena County",
  us_mn_waseca: "Waseca County",
  us_mn_washington: "Washington County",
  us_mn_watonwan: "Watonwan County",
  us_mn_wilkin: "Wilkin County",
  us_mn_winona: "Winona County",
  us_mn_wright: "Wright County",
  us_mn_yellow_medicine: "Yellow Medicine County",
  us_ms_adams: "Adams County",
  us_ms_alcorn: "Alcorn County",
  us_ms_amite: "Amite County",
  us_ms_attala: "Attala County",
  us_ms_benton: "Benton County",
  us_ms_bolivar: "Bolivar County",
  us_ms_calhoun: "Calhoun County",
  us_ms_carroll: "Carroll County",
  us_ms_chickasaw: "Chickasaw County",
  us_ms_choctaw: "Choctaw County",
  us_ms_claiborne: "Claiborne County",
  us_ms_clarke: "Clarke County",
  us_ms_clay: "Clay County",
  us_ms_coahoma: "Coahoma County",
  us_ms_copiah: "Copiah County",
  us_ms_covington: "Covington County",
  us_ms_desoto: "DeSoto County",
  us_ms_forrest: "Forrest County",
  us_ms_franklin: "Franklin County",
  us_ms_george: "George County",
  us_ms_greene: "Greene County",
  us_ms_grenada: "Grenada County",
  us_ms_hancock: "Hancock County",
  us_ms_harrison: "Harrison County",
  us_ms_hinds: "Hinds County",
  us_ms_holmes: "Holmes County",
  us_ms_humphreys: "Humphreys County",
  us_ms_issaquena: "Issaquena County",
  us_ms_itawamba: "Itawamba County",
  us_ms_jackson: "Jackson County",
  us_ms_jasper: "Jasper County",
  us_ms_jefferson: "Jefferson County",
  us_ms_jefferson_davis: "Jefferson Davis County",
  us_ms_jones: "Jones County",
  us_ms_kemper: "Kemper County",
  us_ms_lafayette: "Lafayette County",
  us_ms_lamar: "Lamar County",
  us_ms_lauderdale: "Lauderdale County",
  us_ms_lawrence: "Lawrence County",
  us_ms_leake: "Leake County",
  us_ms_lee: "Lee County",
  us_ms_leflore: "Leflore County",
  us_ms_lincoln: "Lincoln County",
  us_ms_lowndes: "Lowndes County",
  us_ms_madison: "Madison County",
  us_ms_marion: "Marion County",
  us_ms_marshall: "Marshall County",
  us_ms_monroe: "Monroe County",
  us_ms_montgomery: "Montgomery County",
  us_ms_neshoba: "Neshoba County",
  us_ms_newton: "Newton County",
  us_ms_noxubee: "Noxubee County",
  us_ms_oktibbeha: "Oktibbeha County",
  us_ms_panola: "Panola County",
  us_ms_pearl_river: "Pearl River County",
  us_ms_perry: "Perry County",
  us_ms_pike: "Pike County",
  us_ms_pontotoc: "Pontotoc County",
  us_ms_prentiss: "Prentiss County",
  us_ms_quitman: "Quitman County",
  us_ms_rankin: "Rankin County",
  us_ms_scott: "Scott County",
  us_ms_sharkey: "Sharkey County",
  us_ms_simpson: "Simpson County",
  us_ms_smith: "Smith County",
  us_ms_stone: "Stone County",
  us_ms_sunflower: "Sunflower County",
  us_ms_tallahatchie: "Tallahatchie County",
  us_ms_tate: "Tate County",
  us_ms_tippah: "Tippah County",
  us_ms_tishomingo: "Tishomingo County",
  us_ms_tunica: "Tunica County",
  us_ms_union: "Union County",
  us_ms_walthall: "Walthall County",
  us_ms_warren: "Warren County",
  us_ms_washington: "Washington County",
  us_ms_wayne: "Wayne County",
  us_ms_webster: "Webster County",
  us_ms_wilkinson: "Wilkinson County",
  us_ms_winston: "Winston County",
  us_ms_yalobusha: "Yalobusha County",
  us_ms_yazoo: "Yazoo County",
  us_mo_adair: "Adair County",
  us_mo_andrew: "Andrew County",
  us_mo_atchison: "Atchison County",
  us_mo_audrain: "Audrain County",
  us_mo_barry: "Barry County",
  us_mo_barton: "Barton County",
  us_mo_bates: "Bates County",
  us_mo_benton: "Benton County",
  us_mo_bollinger: "Bollinger County",
  us_mo_boone: "Boone County",
  us_mo_buchanan: "Buchanan County",
  us_mo_butler: "Butler County",
  us_mo_caldwell: "Caldwell County",
  us_mo_callaway: "Callaway County",
  us_mo_camden: "Camden County",
  us_mo_cape_girardeau: "Cape Girardeau County",
  us_mo_carroll: "Carroll County",
  us_mo_carter: "Carter County",
  us_mo_cass: "Cass County",
  us_mo_cedar: "Cedar County",
  us_mo_chariton: "Chariton County",
  us_mo_christian: "Christian County",
  us_mo_clark: "Clark County",
  us_mo_clay: "Clay County",
  us_mo_clinton: "Clinton County",
  us_mo_cole: "Cole County",
  us_mo_cooper: "Cooper County",
  us_mo_crawford: "Crawford County",
  us_mo_dade: "Dade County",
  us_mo_dallas: "Dallas County",
  us_mo_daviess: "Daviess County",
  us_mo_dekalb: "DeKalb County",
  us_mo_dent: "Dent County",
  us_mo_douglas: "Douglas County",
  us_mo_dunklin: "Dunklin County",
  us_mo_franklin: "Franklin County",
  us_mo_gasconade: "Gasconade County",
  us_mo_gentry: "Gentry County",
  us_mo_greene: "Greene County",
  us_mo_grundy: "Grundy County",
  us_mo_harrison: "Harrison County",
  us_mo_henry: "Henry County",
  us_mo_hickory: "Hickory County",
  us_mo_holt: "Holt County",
  us_mo_howard: "Howard County",
  us_mo_howell: "Howell County",
  us_mo_iron: "Iron County",
  us_mo_jackson: "Jackson County",
  us_mo_jasper: "Jasper County",
  us_mo_jefferson: "Jefferson County",
  us_mo_johnson: "Johnson County",
  us_mo_knox: "Knox County",
  us_mo_laclede: "Laclede County",
  us_mo_lafayette: "Lafayette County",
  us_mo_lawrence: "Lawrence County",
  us_mo_lewis: "Lewis County",
  us_mo_lincoln: "Lincoln County",
  us_mo_linn: "Linn County",
  us_mo_livingston: "Livingston County",
  us_mo_mcdonald: "McDonald County",
  us_mo_macon: "Macon County",
  us_mo_madison: "Madison County",
  us_mo_maries: "Maries County",
  us_mo_marion: "Marion County",
  us_mo_mercer: "Mercer County",
  us_mo_miller: "Miller County",
  us_mo_mississippi: "Mississippi County",
  us_mo_moniteau: "Moniteau County",
  us_mo_monroe: "Monroe County",
  us_mo_montgomery: "Montgomery County",
  us_mo_morgan: "Morgan County",
  us_mo_new_madrid: "New Madrid County",
  us_mo_newton: "Newton County",
  us_mo_nodaway: "Nodaway County",
  us_mo_oregon: "Oregon County",
  us_mo_osage: "Osage County",
  us_mo_ozark: "Ozark County",
  us_mo_pemiscot: "Pemiscot County",
  us_mo_perry: "Perry County",
  us_mo_pettis: "Pettis County",
  us_mo_phelps: "Phelps County",
  us_mo_pike: "Pike County",
  us_mo_platte: "Platte County",
  us_mo_polk: "Polk County",
  us_mo_pulaski: "Pulaski County",
  us_mo_putnam: "Putnam County",
  us_mo_ralls: "Ralls County",
  us_mo_randolph: "Randolph County",
  us_mo_ray: "Ray County",
  us_mo_reynolds: "Reynolds County",
  us_mo_ripley: "Ripley County",
  us_mo_st_charles: "St. Charles County",
  us_mo_st_clair: "St. Clair County",
  us_mo_ste_genevieve: "Ste. Genevieve County",
  us_mo_st_francois: "St. Francois County",
  us_mo_st_louis: "St. Louis County",
  us_mo_saline: "Saline County",
  us_mo_schuyler: "Schuyler County",
  us_mo_scotland: "Scotland County",
  us_mo_scott: "Scott County",
  us_mo_shannon: "Shannon County",
  us_mo_shelby: "Shelby County",
  us_mo_stoddard: "Stoddard County",
  us_mo_stone: "Stone County",
  us_mo_sullivan: "Sullivan County",
  us_mo_taney: "Taney County",
  us_mo_texas: "Texas County",
  us_mo_vernon: "Vernon County",
  us_mo_warren: "Warren County",
  us_mo_washington: "Washington County",
  us_mo_wayne: "Wayne County",
  us_mo_webster: "Webster County",
  us_mo_worth: "Worth County",
  us_mo_wright: "Wright County",
  us_mo_st_louis_city: "St. Louis city",
  us_mt_beaverhead: "Beaverhead County",
  us_mt_big_horn: "Big Horn County",
  us_mt_blaine: "Blaine County",
  us_mt_broadwater: "Broadwater County",
  us_mt_carbon: "Carbon County",
  us_mt_carter: "Carter County",
  us_mt_cascade: "Cascade County",
  us_mt_chouteau: "Chouteau County",
  us_mt_custer: "Custer County",
  us_mt_daniels: "Daniels County",
  us_mt_dawson: "Dawson County",
  us_mt_deer_lodge: "Deer Lodge County",
  us_mt_fallon: "Fallon County",
  us_mt_fergus: "Fergus County",
  us_mt_flathead: "Flathead County",
  us_mt_gallatin: "Gallatin County",
  us_mt_garfield: "Garfield County",
  us_mt_glacier: "Glacier County",
  us_mt_golden_valley: "Golden Valley County",
  us_mt_granite: "Granite County",
  us_mt_hill: "Hill County",
  us_mt_jefferson: "Jefferson County",
  us_mt_judith_basin: "Judith Basin County",
  us_mt_lake: "Lake County",
  us_mt_lewis_and_clark: "Lewis and Clark County",
  us_mt_liberty: "Liberty County",
  us_mt_lincoln: "Lincoln County",
  us_mt_mccone: "McCone County",
  us_mt_madison: "Madison County",
  us_mt_meagher: "Meagher County",
  us_mt_mineral: "Mineral County",
  us_mt_missoula: "Missoula County",
  us_mt_musselshell: "Musselshell County",
  us_mt_park: "Park County",
  us_mt_petroleum: "Petroleum County",
  us_mt_phillips: "Phillips County",
  us_mt_pondera: "Pondera County",
  us_mt_powder_river: "Powder River County",
  us_mt_powell: "Powell County",
  us_mt_prairie: "Prairie County",
  us_mt_ravalli: "Ravalli County",
  us_mt_richland: "Richland County",
  us_mt_roosevelt: "Roosevelt County",
  us_mt_rosebud: "Rosebud County",
  us_mt_sanders: "Sanders County",
  us_mt_sheridan: "Sheridan County",
  us_mt_silver_bow: "Silver Bow County",
  us_mt_stillwater: "Stillwater County",
  us_mt_sweet_grass: "Sweet Grass County",
  us_mt_teton: "Teton County",
  us_mt_toole: "Toole County",
  us_mt_treasure: "Treasure County",
  us_mt_valley: "Valley County",
  us_mt_wheatland: "Wheatland County",
  us_mt_wibaux: "Wibaux County",
  us_mt_yellowstone: "Yellowstone County",
  us_ne_adams: "Adams County",
  us_ne_antelope: "Antelope County",
  us_ne_arthur: "Arthur County",
  us_ne_banner: "Banner County",
  us_ne_blaine: "Blaine County",
  us_ne_boone: "Boone County",
  us_ne_box_butte: "Box Butte County",
  us_ne_boyd: "Boyd County",
  us_ne_brown: "Brown County",
  us_ne_buffalo: "Buffalo County",
  us_ne_burt: "Burt County",
  us_ne_butler: "Butler County",
  us_ne_cass: "Cass County",
  us_ne_cedar: "Cedar County",
  us_ne_chase: "Chase County",
  us_ne_cherry: "Cherry County",
  us_ne_cheyenne: "Cheyenne County",
  us_ne_clay: "Clay County",
  us_ne_colfax: "Colfax County",
  us_ne_cuming: "Cuming County",
  us_ne_custer: "Custer County",
  us_ne_dakota: "Dakota County",
  us_ne_dawes: "Dawes County",
  us_ne_dawson: "Dawson County",
  us_ne_deuel: "Deuel County",
  us_ne_dixon: "Dixon County",
  us_ne_dodge: "Dodge County",
  us_ne_douglas: "Douglas County",
  us_ne_dundy: "Dundy County",
  us_ne_fillmore: "Fillmore County",
  us_ne_franklin: "Franklin County",
  us_ne_frontier: "Frontier County",
  us_ne_furnas: "Furnas County",
  us_ne_gage: "Gage County",
  us_ne_garden: "Garden County",
  us_ne_garfield: "Garfield County",
  us_ne_gosper: "Gosper County",
  us_ne_grant: "Grant County",
  us_ne_greeley: "Greeley County",
  us_ne_hall: "Hall County",
  us_ne_hamilton: "Hamilton County",
  us_ne_harlan: "Harlan County",
  us_ne_hayes: "Hayes County",
  us_ne_hitchcock: "Hitchcock County",
  us_ne_holt: "Holt County",
  us_ne_hooker: "Hooker County",
  us_ne_howard: "Howard County",
  us_ne_jefferson: "Jefferson County",
  us_ne_johnson: "Johnson County",
  us_ne_kearney: "Kearney County",
  us_ne_keith: "Keith County",
  us_ne_keya_paha: "Keya Paha County",
  us_ne_kimball: "Kimball County",
  us_ne_knox: "Knox County",
  us_ne_lancaster: "Lancaster County",
  us_ne_lincoln: "Lincoln County",
  us_ne_logan: "Logan County",
  us_ne_loup: "Loup County",
  us_ne_mcpherson: "McPherson County",
  us_ne_madison: "Madison County",
  us_ne_merrick: "Merrick County",
  us_ne_morrill: "Morrill County",
  us_ne_nance: "Nance County",
  us_ne_nemaha: "Nemaha County",
  us_ne_nuckolls: "Nuckolls County",
  us_ne_otoe: "Otoe County",
  us_ne_pawnee: "Pawnee County",
  us_ne_perkins: "Perkins County",
  us_ne_phelps: "Phelps County",
  us_ne_pierce: "Pierce County",
  us_ne_platte: "Platte County",
  us_ne_polk: "Polk County",
  us_ne_red_willow: "Red Willow County",
  us_ne_richardson: "Richardson County",
  us_ne_rock: "Rock County",
  us_ne_saline: "Saline County",
  us_ne_sarpy: "Sarpy County",
  us_ne_saunders: "Saunders County",
  us_ne_scotts_bluff: "Scotts Bluff County",
  us_ne_seward: "Seward County",
  us_ne_sheridan: "Sheridan County",
  us_ne_sherman: "Sherman County",
  us_ne_sioux: "Sioux County",
  us_ne_stanton: "Stanton County",
  us_ne_thayer: "Thayer County",
  us_ne_thomas: "Thomas County",
  us_ne_thurston: "Thurston County",
  us_ne_valley: "Valley County",
  us_ne_washington: "Washington County",
  us_ne_wayne: "Wayne County",
  us_ne_webster: "Webster County",
  us_ne_wheeler: "Wheeler County",
  us_ne_york: "York County",
  us_nv_churchill: "Churchill County",
  us_nv_clark: "Clark County",
  us_nv_douglas: "Douglas County",
  us_nv_elko: "Elko County",
  us_nv_esmeralda: "Esmeralda County",
  us_nv_eureka: "Eureka County",
  us_nv_humboldt: "Humboldt County",
  us_nv_lander: "Lander County",
  us_nv_lincoln: "Lincoln County",
  us_nv_lyon: "Lyon County",
  us_nv_mineral: "Mineral County",
  us_nv_nye: "Nye County",
  us_nv_pershing: "Pershing County",
  us_nv_storey: "Storey County",
  us_nv_washoe: "Washoe County",
  us_nv_white_pine: "White Pine County",
  us_nv_carson_city: "Carson City",
  us_nh_belknap: "Belknap County",
  us_nh_carroll: "Carroll County",
  us_nh_cheshire: "Cheshire County",
  us_nh_coos: "Coos County",
  us_nh_grafton: "Grafton County",
  us_nh_hillsborough: "Hillsborough County",
  us_nh_merrimack: "Merrimack County",
  us_nh_rockingham: "Rockingham County",
  us_nh_strafford: "Strafford County",
  us_nh_sullivan: "Sullivan County",
  us_nj_atlantic: "Atlantic County",
  us_nj_bergen: "Bergen County",
  us_nj_burlington: "Burlington County",
  us_nj_camden: "Camden County",
  us_nj_cape_may: "Cape May County",
  us_nj_cumberland: "Cumberland County",
  us_nj_essex: "Essex County",
  us_nj_gloucester: "Gloucester County",
  us_nj_hudson: "Hudson County",
  us_nj_hunterdon: "Hunterdon County",
  us_nj_mercer: "Mercer County",
  us_nj_middlesex: "Middlesex County",
  us_nj_monmouth: "Monmouth County",
  us_nj_morris: "Morris County",
  us_nj_ocean: "Ocean County",
  us_nj_passaic: "Passaic County",
  us_nj_salem: "Salem County",
  us_nj_somerset: "Somerset County",
  us_nj_sussex: "Sussex County",
  us_nj_union: "Union County",
  us_nj_warren: "Warren County",
  us_nm_bernalillo: "Bernalillo County",
  us_nm_catron: "Catron County",
  us_nm_chaves: "Chaves County",
  us_nm_cibola: "Cibola County",
  us_nm_colfax: "Colfax County",
  us_nm_curry: "Curry County",
  us_nm_de_baca: "De Baca County",
  us_nm_doña_ana: "Doña Ana County",
  us_nm_eddy: "Eddy County",
  us_nm_grant: "Grant County",
  us_nm_guadalupe: "Guadalupe County",
  us_nm_harding: "Harding County",
  us_nm_hidalgo: "Hidalgo County",
  us_nm_lea: "Lea County",
  us_nm_lincoln: "Lincoln County",
  us_nm_los_alamos: "Los Alamos County",
  us_nm_luna: "Luna County",
  us_nm_mckinley: "McKinley County",
  us_nm_mora: "Mora County",
  us_nm_otero: "Otero County",
  us_nm_quay: "Quay County",
  us_nm_rio_arriba: "Rio Arriba County",
  us_nm_roosevelt: "Roosevelt County",
  us_nm_sandoval: "Sandoval County",
  us_nm_san_juan: "San Juan County",
  us_nm_san_miguel: "San Miguel County",
  us_nm_santa_fe: "Santa Fe County",
  us_nm_sierra: "Sierra County",
  us_nm_socorro: "Socorro County",
  us_nm_taos: "Taos County",
  us_nm_torrance: "Torrance County",
  us_nm_union: "Union County",
  us_nm_valencia: "Valencia County",
  us_ny_albany: "Albany County",
  us_ny_allegany: "Allegany County",
  us_ny_bronx: "Bronx County",
  us_ny_broome: "Broome County",
  us_ny_cattaraugus: "Cattaraugus County",
  us_ny_cayuga: "Cayuga County",
  us_ny_chautauqua: "Chautauqua County",
  us_ny_chemung: "Chemung County",
  us_ny_chenango: "Chenango County",
  us_ny_clinton: "Clinton County",
  us_ny_columbia: "Columbia County",
  us_ny_cortland: "Cortland County",
  us_ny_delaware: "Delaware County",
  us_ny_dutchess: "Dutchess County",
  us_ny_erie: "Erie County",
  us_ny_essex: "Essex County",
  us_ny_franklin: "Franklin County",
  us_ny_fulton: "Fulton County",
  us_ny_genesee: "Genesee County",
  us_ny_greene: "Greene County",
  us_ny_hamilton: "Hamilton County",
  us_ny_herkimer: "Herkimer County",
  us_ny_jefferson: "Jefferson County",
  us_ny_kings: "Kings County",
  us_ny_lewis: "Lewis County",
  us_ny_livingston: "Livingston County",
  us_ny_madison: "Madison County",
  us_ny_monroe: "Monroe County",
  us_ny_montgomery: "Montgomery County",
  us_ny_nassau: "Nassau County",
  us_ny_new_york: "New York County",
  us_ny_niagara: "Niagara County",
  us_ny_oneida: "Oneida County",
  us_ny_onondaga: "Onondaga County",
  us_ny_ontario: "Ontario County",
  us_ny_orange: "Orange County",
  us_ny_orleans: "Orleans County",
  us_ny_oswego: "Oswego County",
  us_ny_otsego: "Otsego County",
  us_ny_putnam: "Putnam County",
  us_ny_queens: "Queens County",
  us_ny_rensselaer: "Rensselaer County",
  us_ny_richmond: "Richmond County",
  us_ny_rockland: "Rockland County",
  us_ny_st_lawrence: "St. Lawrence County",
  us_ny_saratoga: "Saratoga County",
  us_ny_schenectady: "Schenectady County",
  us_ny_schoharie: "Schoharie County",
  us_ny_schuyler: "Schuyler County",
  us_ny_seneca: "Seneca County",
  us_ny_steuben: "Steuben County",
  us_ny_suffolk: "Suffolk County",
  us_ny_sullivan: "Sullivan County",
  us_ny_tioga: "Tioga County",
  us_ny_tompkins: "Tompkins County",
  us_ny_ulster: "Ulster County",
  us_ny_warren: "Warren County",
  us_ny_washington: "Washington County",
  us_ny_wayne: "Wayne County",
  us_ny_westchester: "Westchester County",
  us_ny_wyoming: "Wyoming County",
  us_ny_yates: "Yates County",
  us_nc_alamance: "Alamance County",
  us_nc_alexander: "Alexander County",
  us_nc_alleghany: "Alleghany County",
  us_nc_anson: "Anson County",
  us_nc_ashe: "Ashe County",
  us_nc_avery: "Avery County",
  us_nc_beaufort: "Beaufort County",
  us_nc_bertie: "Bertie County",
  us_nc_bladen: "Bladen County",
  us_nc_brunswick: "Brunswick County",
  us_nc_buncombe: "Buncombe County",
  us_nc_burke: "Burke County",
  us_nc_cabarrus: "Cabarrus County",
  us_nc_caldwell: "Caldwell County",
  us_nc_camden: "Camden County",
  us_nc_carteret: "Carteret County",
  us_nc_caswell: "Caswell County",
  us_nc_catawba: "Catawba County",
  us_nc_chatham: "Chatham County",
  us_nc_cherokee: "Cherokee County",
  us_nc_chowan: "Chowan County",
  us_nc_clay: "Clay County",
  us_nc_cleveland: "Cleveland County",
  us_nc_columbus: "Columbus County",
  us_nc_craven: "Craven County",
  us_nc_cumberland: "Cumberland County",
  us_nc_currituck: "Currituck County",
  us_nc_dare: "Dare County",
  us_nc_davidson: "Davidson County",
  us_nc_davie: "Davie County",
  us_nc_duplin: "Duplin County",
  us_nc_durham: "Durham County",
  us_nc_edgecombe: "Edgecombe County",
  us_nc_forsyth: "Forsyth County",
  us_nc_franklin: "Franklin County",
  us_nc_gaston: "Gaston County",
  us_nc_gates: "Gates County",
  us_nc_graham: "Graham County",
  us_nc_granville: "Granville County",
  us_nc_greene: "Greene County",
  us_nc_guilford: "Guilford County",
  us_nc_halifax: "Halifax County",
  us_nc_harnett: "Harnett County",
  us_nc_haywood: "Haywood County",
  us_nc_henderson: "Henderson County",
  us_nc_hertford: "Hertford County",
  us_nc_hoke: "Hoke County",
  us_nc_hyde: "Hyde County",
  us_nc_iredell: "Iredell County",
  us_nc_jackson: "Jackson County",
  us_nc_johnston: "Johnston County",
  us_nc_jones: "Jones County",
  us_nc_lee: "Lee County",
  us_nc_lenoir: "Lenoir County",
  us_nc_lincoln: "Lincoln County",
  us_nc_mcdowell: "McDowell County",
  us_nc_macon: "Macon County",
  us_nc_madison: "Madison County",
  us_nc_martin: "Martin County",
  us_nc_mecklenburg: "Mecklenburg County",
  us_nc_mitchell: "Mitchell County",
  us_nc_montgomery: "Montgomery County",
  us_nc_moore: "Moore County",
  us_nc_nash: "Nash County",
  us_nc_new_hanover: "New Hanover County",
  us_nc_northampton: "Northampton County",
  us_nc_onslow: "Onslow County",
  us_nc_orange: "Orange County",
  us_nc_pamlico: "Pamlico County",
  us_nc_pasquotank: "Pasquotank County",
  us_nc_pender: "Pender County",
  us_nc_perquimans: "Perquimans County",
  us_nc_person: "Person County",
  us_nc_pitt: "Pitt County",
  us_nc_polk: "Polk County",
  us_nc_randolph: "Randolph County",
  us_nc_richmond: "Richmond County",
  us_nc_robeson: "Robeson County",
  us_nc_rockingham: "Rockingham County",
  us_nc_rowan: "Rowan County",
  us_nc_rutherford: "Rutherford County",
  us_nc_sampson: "Sampson County",
  us_nc_scotland: "Scotland County",
  us_nc_stanly: "Stanly County",
  us_nc_stokes: "Stokes County",
  us_nc_surry: "Surry County",
  us_nc_swain: "Swain County",
  us_nc_transylvania: "Transylvania County",
  us_nc_tyrrell: "Tyrrell County",
  us_nc_union: "Union County",
  us_nc_vance: "Vance County",
  us_nc_wake: "Wake County",
  us_nc_warren: "Warren County",
  us_nc_washington: "Washington County",
  us_nc_watauga: "Watauga County",
  us_nc_wayne: "Wayne County",
  us_nc_wilkes: "Wilkes County",
  us_nc_wilson: "Wilson County",
  us_nc_yadkin: "Yadkin County",
  us_nc_yancey: "Yancey County",
  us_nd_adams: "Adams County",
  us_nd_barnes: "Barnes County",
  us_nd_benson: "Benson County",
  us_nd_billings: "Billings County",
  us_nd_bottineau: "Bottineau County",
  us_nd_bowman: "Bowman County",
  us_nd_burke: "Burke County",
  us_nd_burleigh: "Burleigh County",
  us_nd_cass: "Cass County",
  us_nd_cavalier: "Cavalier County",
  us_nd_dickey: "Dickey County",
  us_nd_divide: "Divide County",
  us_nd_dunn: "Dunn County",
  us_nd_eddy: "Eddy County",
  us_nd_emmons: "Emmons County",
  us_nd_foster: "Foster County",
  us_nd_golden_valley: "Golden Valley County",
  us_nd_grand_forks: "Grand Forks County",
  us_nd_grant: "Grant County",
  us_nd_griggs: "Griggs County",
  us_nd_hettinger: "Hettinger County",
  us_nd_kidder: "Kidder County",
  us_nd_lamoure: "LaMoure County",
  us_nd_logan: "Logan County",
  us_nd_mchenry: "McHenry County",
  us_nd_mcintosh: "McIntosh County",
  us_nd_mckenzie: "McKenzie County",
  us_nd_mclean: "McLean County",
  us_nd_mercer: "Mercer County",
  us_nd_morton: "Morton County",
  us_nd_mountrail: "Mountrail County",
  us_nd_nelson: "Nelson County",
  us_nd_oliver: "Oliver County",
  us_nd_pembina: "Pembina County",
  us_nd_pierce: "Pierce County",
  us_nd_ramsey: "Ramsey County",
  us_nd_ransom: "Ransom County",
  us_nd_renville: "Renville County",
  us_nd_richland: "Richland County",
  us_nd_rolette: "Rolette County",
  us_nd_sargent: "Sargent County",
  us_nd_sheridan: "Sheridan County",
  us_nd_sioux: "Sioux County",
  us_nd_slope: "Slope County",
  us_nd_stark: "Stark County",
  us_nd_steele: "Steele County",
  us_nd_stutsman: "Stutsman County",
  us_nd_towner: "Towner County",
  us_nd_traill: "Traill County",
  us_nd_walsh: "Walsh County",
  us_nd_ward: "Ward County",
  us_nd_wells: "Wells County",
  us_nd_williams: "Williams County",
  us_oh_adams: "Adams County",
  us_oh_allen: "Allen County",
  us_oh_ashland: "Ashland County",
  us_oh_ashtabula: "Ashtabula County",
  us_oh_athens: "Athens County",
  us_oh_auglaize: "Auglaize County",
  us_oh_belmont: "Belmont County",
  us_oh_brown: "Brown County",
  us_oh_butler: "Butler County",
  us_oh_carroll: "Carroll County",
  us_oh_champaign: "Champaign County",
  us_oh_clark: "Clark County",
  us_oh_clermont: "Clermont County",
  us_oh_clinton: "Clinton County",
  us_oh_columbiana: "Columbiana County",
  us_oh_coshocton: "Coshocton County",
  us_oh_crawford: "Crawford County",
  us_oh_cuyahoga: "Cuyahoga County",
  us_oh_darke: "Darke County",
  us_oh_defiance: "Defiance County",
  us_oh_delaware: "Delaware County",
  us_oh_erie: "Erie County",
  us_oh_fairfield: "Fairfield County",
  us_oh_fayette: "Fayette County",
  us_oh_franklin: "Franklin County",
  us_oh_fulton: "Fulton County",
  us_oh_gallia: "Gallia County",
  us_oh_geauga: "Geauga County",
  us_oh_greene: "Greene County",
  us_oh_guernsey: "Guernsey County",
  us_oh_hamilton: "Hamilton County",
  us_oh_hancock: "Hancock County",
  us_oh_hardin: "Hardin County",
  us_oh_harrison: "Harrison County",
  us_oh_henry: "Henry County",
  us_oh_highland: "Highland County",
  us_oh_hocking: "Hocking County",
  us_oh_holmes: "Holmes County",
  us_oh_huron: "Huron County",
  us_oh_jackson: "Jackson County",
  us_oh_jefferson: "Jefferson County",
  us_oh_knox: "Knox County",
  us_oh_lake: "Lake County",
  us_oh_lawrence: "Lawrence County",
  us_oh_licking: "Licking County",
  us_oh_logan: "Logan County",
  us_oh_lorain: "Lorain County",
  us_oh_lucas: "Lucas County",
  us_oh_madison: "Madison County",
  us_oh_mahoning: "Mahoning County",
  us_oh_marion: "Marion County",
  us_oh_medina: "Medina County",
  us_oh_meigs: "Meigs County",
  us_oh_mercer: "Mercer County",
  us_oh_miami: "Miami County",
  us_oh_monroe: "Monroe County",
  us_oh_montgomery: "Montgomery County",
  us_oh_morgan: "Morgan County",
  us_oh_morrow: "Morrow County",
  us_oh_muskingum: "Muskingum County",
  us_oh_noble: "Noble County",
  us_oh_ottawa: "Ottawa County",
  us_oh_paulding: "Paulding County",
  us_oh_perry: "Perry County",
  us_oh_pickaway: "Pickaway County",
  us_oh_pike: "Pike County",
  us_oh_portage: "Portage County",
  us_oh_preble: "Preble County",
  us_oh_putnam: "Putnam County",
  us_oh_richland: "Richland County",
  us_oh_ross: "Ross County",
  us_oh_sandusky: "Sandusky County",
  us_oh_scioto: "Scioto County",
  us_oh_seneca: "Seneca County",
  us_oh_shelby: "Shelby County",
  us_oh_stark: "Stark County",
  us_oh_summit: "Summit County",
  us_oh_trumbull: "Trumbull County",
  us_oh_tuscarawas: "Tuscarawas County",
  us_oh_union: "Union County",
  us_oh_van_wert: "Van Wert County",
  us_oh_vinton: "Vinton County",
  us_oh_warren: "Warren County",
  us_oh_washington: "Washington County",
  us_oh_wayne: "Wayne County",
  us_oh_williams: "Williams County",
  us_oh_wood: "Wood County",
  us_oh_wyandot: "Wyandot County",
  us_ok_adair: "Adair County",
  us_ok_alfalfa: "Alfalfa County",
  us_ok_atoka: "Atoka County",
  us_ok_beaver: "Beaver County",
  us_ok_beckham: "Beckham County",
  us_ok_blaine: "Blaine County",
  us_ok_bryan: "Bryan County",
  us_ok_caddo: "Caddo County",
  us_ok_canadian: "Canadian County",
  us_ok_carter: "Carter County",
  us_ok_cherokee: "Cherokee County",
  us_ok_choctaw: "Choctaw County",
  us_ok_cimarron: "Cimarron County",
  us_ok_cleveland: "Cleveland County",
  us_ok_coal: "Coal County",
  us_ok_comanche: "Comanche County",
  us_ok_cotton: "Cotton County",
  us_ok_craig: "Craig County",
  us_ok_creek: "Creek County",
  us_ok_custer: "Custer County",
  us_ok_delaware: "Delaware County",
  us_ok_dewey: "Dewey County",
  us_ok_ellis: "Ellis County",
  us_ok_garfield: "Garfield County",
  us_ok_garvin: "Garvin County",
  us_ok_grady: "Grady County",
  us_ok_grant: "Grant County",
  us_ok_greer: "Greer County",
  us_ok_harmon: "Harmon County",
  us_ok_harper: "Harper County",
  us_ok_haskell: "Haskell County",
  us_ok_hughes: "Hughes County",
  us_ok_jackson: "Jackson County",
  us_ok_jefferson: "Jefferson County",
  us_ok_johnston: "Johnston County",
  us_ok_kay: "Kay County",
  us_ok_kingfisher: "Kingfisher County",
  us_ok_kiowa: "Kiowa County",
  us_ok_latimer: "Latimer County",
  us_ok_le_flore: "Le Flore County",
  us_ok_lincoln: "Lincoln County",
  us_ok_logan: "Logan County",
  us_ok_love: "Love County",
  us_ok_mcclain: "McClain County",
  us_ok_mccurtain: "McCurtain County",
  us_ok_mcintosh: "McIntosh County",
  us_ok_major: "Major County",
  us_ok_marshall: "Marshall County",
  us_ok_mayes: "Mayes County",
  us_ok_murray: "Murray County",
  us_ok_muskogee: "Muskogee County",
  us_ok_noble: "Noble County",
  us_ok_nowata: "Nowata County",
  us_ok_okfuskee: "Okfuskee County",
  us_ok_oklahoma: "Oklahoma County",
  us_ok_okmulgee: "Okmulgee County",
  us_ok_osage: "Osage County",
  us_ok_ottawa: "Ottawa County",
  us_ok_pawnee: "Pawnee County",
  us_ok_payne: "Payne County",
  us_ok_pittsburg: "Pittsburg County",
  us_ok_pontotoc: "Pontotoc County",
  us_ok_pottawatomie: "Pottawatomie County",
  us_ok_pushmataha: "Pushmataha County",
  us_ok_roger_mills: "Roger Mills County",
  us_ok_rogers: "Rogers County",
  us_ok_seminole: "Seminole County",
  us_ok_sequoyah: "Sequoyah County",
  us_ok_stephens: "Stephens County",
  us_ok_texas: "Texas County",
  us_ok_tillman: "Tillman County",
  us_ok_tulsa: "Tulsa County",
  us_ok_wagoner: "Wagoner County",
  us_ok_washington: "Washington County",
  us_ok_washita: "Washita County",
  us_ok_woods: "Woods County",
  us_ok_woodward: "Woodward County",
  us_or_baker: "Baker County",
  us_or_benton: "Benton County",
  us_or_clackamas: "Clackamas County",
  us_or_clatsop: "Clatsop County",
  us_or_columbia: "Columbia County",
  us_or_coos: "Coos County",
  us_or_crook: "Crook County",
  us_or_curry: "Curry County",
  us_or_deschutes: "Deschutes County",
  us_or_douglas: "Douglas County",
  us_or_gilliam: "Gilliam County",
  us_or_grant: "Grant County",
  us_or_harney: "Harney County",
  us_or_hood_river: "Hood River County",
  us_or_jackson: "Jackson County",
  us_or_jefferson: "Jefferson County",
  us_or_josephine: "Josephine County",
  us_or_klamath: "Klamath County",
  us_or_lake: "Lake County",
  us_or_lane: "Lane County",
  us_or_lincoln: "Lincoln County",
  us_or_linn: "Linn County",
  us_or_malheur: "Malheur County",
  us_or_marion: "Marion County",
  us_or_morrow: "Morrow County",
  us_or_multnomah: "Multnomah County",
  us_or_polk: "Polk County",
  us_or_sherman: "Sherman County",
  us_or_tillamook: "Tillamook County",
  us_or_umatilla: "Umatilla County",
  us_or_union: "Union County",
  us_or_wallowa: "Wallowa County",
  us_or_wasco: "Wasco County",
  us_or_washington: "Washington County",
  us_or_wheeler: "Wheeler County",
  us_or_yamhill: "Yamhill County",
  us_pa_adams: "Adams County",
  us_pa_allegheny: "Allegheny County",
  us_pa_armstrong: "Armstrong County",
  us_pa_beaver: "Beaver County",
  us_pa_bedford: "Bedford County",
  us_pa_berks: "Berks County",
  us_pa_blair: "Blair County",
  us_pa_bradford: "Bradford County",
  us_pa_bucks: "Bucks County",
  us_pa_butler: "Butler County",
  us_pa_cambria: "Cambria County",
  us_pa_cameron: "Cameron County",
  us_pa_carbon: "Carbon County",
  us_pa_centre: "Centre County",
  us_pa_chester: "Chester County",
  us_pa_clarion: "Clarion County",
  us_pa_clearfield: "Clearfield County",
  us_pa_clinton: "Clinton County",
  us_pa_columbia: "Columbia County",
  us_pa_crawford: "Crawford County",
  us_pa_cumberland: "Cumberland County",
  us_pa_dauphin: "Dauphin County",
  us_pa_delaware: "Delaware County",
  us_pa_elk: "Elk County",
  us_pa_erie: "Erie County",
  us_pa_fayette: "Fayette County",
  us_pa_forest: "Forest County",
  us_pa_franklin: "Franklin County",
  us_pa_fulton: "Fulton County",
  us_pa_greene: "Greene County",
  us_pa_huntingdon: "Huntingdon County",
  us_pa_indiana: "Indiana County",
  us_pa_jefferson: "Jefferson County",
  us_pa_juniata: "Juniata County",
  us_pa_lackawanna: "Lackawanna County",
  us_pa_lancaster: "Lancaster County",
  us_pa_lawrence: "Lawrence County",
  us_pa_lebanon: "Lebanon County",
  us_pa_lehigh: "Lehigh County",
  us_pa_luzerne: "Luzerne County",
  us_pa_lycoming: "Lycoming County",
  us_pa_mckean: "McKean County",
  us_pa_mercer: "Mercer County",
  us_pa_mifflin: "Mifflin County",
  us_pa_monroe: "Monroe County",
  us_pa_montgomery: "Montgomery County",
  us_pa_montour: "Montour County",
  us_pa_northampton: "Northampton County",
  us_pa_northumberland: "Northumberland County",
  us_pa_perry: "Perry County",
  us_pa_philadelphia: "Philadelphia County",
  us_pa_pike: "Pike County",
  us_pa_potter: "Potter County",
  us_pa_schuylkill: "Schuylkill County",
  us_pa_snyder: "Snyder County",
  us_pa_somerset: "Somerset County",
  us_pa_sullivan: "Sullivan County",
  us_pa_susquehanna: "Susquehanna County",
  us_pa_tioga: "Tioga County",
  us_pa_union: "Union County",
  us_pa_venango: "Venango County",
  us_pa_warren: "Warren County",
  us_pa_washington: "Washington County",
  us_pa_wayne: "Wayne County",
  us_pa_westmoreland: "Westmoreland County",
  us_pa_wyoming: "Wyoming County",
  us_pa_york: "York County",
  us_ri_bristol: "Bristol County",
  us_ri_kent: "Kent County",
  us_ri_newport: "Newport County",
  us_ri_providence: "Providence County",
  us_ri_washington: "Washington County",
  us_sc_abbeville: "Abbeville County",
  us_sc_aiken: "Aiken County",
  us_sc_allendale: "Allendale County",
  us_sc_anderson: "Anderson County",
  us_sc_bamberg: "Bamberg County",
  us_sc_barnwell: "Barnwell County",
  us_sc_beaufort: "Beaufort County",
  us_sc_berkeley: "Berkeley County",
  us_sc_calhoun: "Calhoun County",
  us_sc_charleston: "Charleston County",
  us_sc_cherokee: "Cherokee County",
  us_sc_chester: "Chester County",
  us_sc_chesterfield: "Chesterfield County",
  us_sc_clarendon: "Clarendon County",
  us_sc_colleton: "Colleton County",
  us_sc_darlington: "Darlington County",
  us_sc_dillon: "Dillon County",
  us_sc_dorchester: "Dorchester County",
  us_sc_edgefield: "Edgefield County",
  us_sc_fairfield: "Fairfield County",
  us_sc_florence: "Florence County",
  us_sc_georgetown: "Georgetown County",
  us_sc_greenville: "Greenville County",
  us_sc_greenwood: "Greenwood County",
  us_sc_hampton: "Hampton County",
  us_sc_horry: "Horry County",
  us_sc_jasper: "Jasper County",
  us_sc_kershaw: "Kershaw County",
  us_sc_lancaster: "Lancaster County",
  us_sc_laurens: "Laurens County",
  us_sc_lee: "Lee County",
  us_sc_lexington: "Lexington County",
  us_sc_mccormick: "McCormick County",
  us_sc_marion: "Marion County",
  us_sc_marlboro: "Marlboro County",
  us_sc_newberry: "Newberry County",
  us_sc_oconee: "Oconee County",
  us_sc_orangeburg: "Orangeburg County",
  us_sc_pickens: "Pickens County",
  us_sc_richland: "Richland County",
  us_sc_saluda: "Saluda County",
  us_sc_spartanburg: "Spartanburg County",
  us_sc_sumter: "Sumter County",
  us_sc_union: "Union County",
  us_sc_williamsburg: "Williamsburg County",
  us_sc_york: "York County",
  us_sd_aurora: "Aurora County",
  us_sd_beadle: "Beadle County",
  us_sd_bennett: "Bennett County",
  us_sd_bon_homme: "Bon Homme County",
  us_sd_brookings: "Brookings County",
  us_sd_brown: "Brown County",
  us_sd_brule: "Brule County",
  us_sd_buffalo: "Buffalo County",
  us_sd_butte: "Butte County",
  us_sd_campbell: "Campbell County",
  us_sd_charles_mix: "Charles Mix County",
  us_sd_clark: "Clark County",
  us_sd_clay: "Clay County",
  us_sd_codington: "Codington County",
  us_sd_corson: "Corson County",
  us_sd_custer: "Custer County",
  us_sd_davison: "Davison County",
  us_sd_day: "Day County",
  us_sd_deuel: "Deuel County",
  us_sd_dewey: "Dewey County",
  us_sd_douglas: "Douglas County",
  us_sd_edmunds: "Edmunds County",
  us_sd_fall_river: "Fall River County",
  us_sd_faulk: "Faulk County",
  us_sd_grant: "Grant County",
  us_sd_gregory: "Gregory County",
  us_sd_haakon: "Haakon County",
  us_sd_hamlin: "Hamlin County",
  us_sd_hand: "Hand County",
  us_sd_hanson: "Hanson County",
  us_sd_harding: "Harding County",
  us_sd_hughes: "Hughes County",
  us_sd_hutchinson: "Hutchinson County",
  us_sd_hyde: "Hyde County",
  us_sd_jackson: "Jackson County",
  us_sd_jerauld: "Jerauld County",
  us_sd_jones: "Jones County",
  us_sd_kingsbury: "Kingsbury County",
  us_sd_lake: "Lake County",
  us_sd_lawrence: "Lawrence County",
  us_sd_lincoln: "Lincoln County",
  us_sd_lyman: "Lyman County",
  us_sd_mccook: "McCook County",
  us_sd_mcpherson: "McPherson County",
  us_sd_marshall: "Marshall County",
  us_sd_meade: "Meade County",
  us_sd_mellette: "Mellette County",
  us_sd_miner: "Miner County",
  us_sd_minnehaha: "Minnehaha County",
  us_sd_moody: "Moody County",
  us_sd_oglala_lakota: "Oglala Lakota County",
  us_sd_pennington: "Pennington County",
  us_sd_perkins: "Perkins County",
  us_sd_potter: "Potter County",
  us_sd_roberts: "Roberts County",
  us_sd_sanborn: "Sanborn County",
  us_sd_shannon: "Shannon County",
  us_sd_spink: "Spink County",
  us_sd_stanley: "Stanley County",
  us_sd_sully: "Sully County",
  us_sd_todd: "Todd County",
  us_sd_tripp: "Tripp County",
  us_sd_turner: "Turner County",
  us_sd_union: "Union County",
  us_sd_walworth: "Walworth County",
  us_sd_yankton: "Yankton County",
  us_sd_ziebach: "Ziebach County",
  us_tn_anderson: "Anderson County",
  us_tn_bedford: "Bedford County",
  us_tn_benton: "Benton County",
  us_tn_bledsoe: "Bledsoe County",
  us_tn_blount: "Blount County",
  us_tn_bradley: "Bradley County",
  us_tn_campbell: "Campbell County",
  us_tn_cannon: "Cannon County",
  us_tn_carroll: "Carroll County",
  us_tn_carter: "Carter County",
  us_tn_cheatham: "Cheatham County",
  us_tn_chester: "Chester County",
  us_tn_claiborne: "Claiborne County",
  us_tn_clay: "Clay County",
  us_tn_cocke: "Cocke County",
  us_tn_coffee: "Coffee County",
  us_tn_crockett: "Crockett County",
  us_tn_cumberland: "Cumberland County",
  us_tn_davidson: "Davidson County",
  us_tn_decatur: "Decatur County",
  us_tn_dekalb: "DeKalb County",
  us_tn_dickson: "Dickson County",
  us_tn_dyer: "Dyer County",
  us_tn_fayette: "Fayette County",
  us_tn_fentress: "Fentress County",
  us_tn_franklin: "Franklin County",
  us_tn_gibson: "Gibson County",
  us_tn_giles: "Giles County",
  us_tn_grainger: "Grainger County",
  us_tn_greene: "Greene County",
  us_tn_grundy: "Grundy County",
  us_tn_hamblen: "Hamblen County",
  us_tn_hamilton: "Hamilton County",
  us_tn_hancock: "Hancock County",
  us_tn_hardeman: "Hardeman County",
  us_tn_hardin: "Hardin County",
  us_tn_hawkins: "Hawkins County",
  us_tn_haywood: "Haywood County",
  us_tn_henderson: "Henderson County",
  us_tn_henry: "Henry County",
  us_tn_hickman: "Hickman County",
  us_tn_houston: "Houston County",
  us_tn_humphreys: "Humphreys County",
  us_tn_jackson: "Jackson County",
  us_tn_jefferson: "Jefferson County",
  us_tn_johnson: "Johnson County",
  us_tn_knox: "Knox County",
  us_tn_lake: "Lake County",
  us_tn_lauderdale: "Lauderdale County",
  us_tn_lawrence: "Lawrence County",
  us_tn_lewis: "Lewis County",
  us_tn_lincoln: "Lincoln County",
  us_tn_loudon: "Loudon County",
  us_tn_mcminn: "McMinn County",
  us_tn_mcnairy: "McNairy County",
  us_tn_macon: "Macon County",
  us_tn_madison: "Madison County",
  us_tn_marion: "Marion County",
  us_tn_marshall: "Marshall County",
  us_tn_maury: "Maury County",
  us_tn_meigs: "Meigs County",
  us_tn_monroe: "Monroe County",
  us_tn_montgomery: "Montgomery County",
  us_tn_moore: "Moore County",
  us_tn_morgan: "Morgan County",
  us_tn_obion: "Obion County",
  us_tn_overton: "Overton County",
  us_tn_perry: "Perry County",
  us_tn_pickett: "Pickett County",
  us_tn_polk: "Polk County",
  us_tn_putnam: "Putnam County",
  us_tn_rhea: "Rhea County",
  us_tn_roane: "Roane County",
  us_tn_robertson: "Robertson County",
  us_tn_rutherford: "Rutherford County",
  us_tn_scott: "Scott County",
  us_tn_sequatchie: "Sequatchie County",
  us_tn_sevier: "Sevier County",
  us_tn_shelby: "Shelby County",
  us_tn_smith: "Smith County",
  us_tn_stewart: "Stewart County",
  us_tn_sullivan: "Sullivan County",
  us_tn_sumner: "Sumner County",
  us_tn_tipton: "Tipton County",
  us_tn_trousdale: "Trousdale County",
  us_tn_unicoi: "Unicoi County",
  us_tn_union: "Union County",
  us_tn_van_buren: "Van Buren County",
  us_tn_warren: "Warren County",
  us_tn_washington: "Washington County",
  us_tn_wayne: "Wayne County",
  us_tn_weakley: "Weakley County",
  us_tn_white: "White County",
  us_tn_williamson: "Williamson County",
  us_tn_wilson: "Wilson County",
  us_tx_anderson: "Anderson County",
  us_tx_andrews: "Andrews County",
  us_tx_angelina: "Angelina County",
  us_tx_aransas: "Aransas County",
  us_tx_archer: "Archer County",
  us_tx_armstrong: "Armstrong County",
  us_tx_atascosa: "Atascosa County",
  us_tx_austin: "Austin County",
  us_tx_bailey: "Bailey County",
  us_tx_bandera: "Bandera County",
  us_tx_bastrop: "Bastrop County",
  us_tx_baylor: "Baylor County",
  us_tx_bee: "Bee County",
  us_tx_bell: "Bell County",
  us_tx_bexar: "Bexar County",
  us_tx_blanco: "Blanco County",
  us_tx_borden: "Borden County",
  us_tx_bosque: "Bosque County",
  us_tx_bowie: "Bowie County",
  us_tx_brazoria: "Brazoria County",
  us_tx_brazos: "Brazos County",
  us_tx_brewster: "Brewster County",
  us_tx_briscoe: "Briscoe County",
  us_tx_brooks: "Brooks County",
  us_tx_brown: "Brown County",
  us_tx_burleson: "Burleson County",
  us_tx_burnet: "Burnet County",
  us_tx_caldwell: "Caldwell County",
  us_tx_calhoun: "Calhoun County",
  us_tx_callahan: "Callahan County",
  us_tx_cameron: "Cameron County",
  us_tx_camp: "Camp County",
  us_tx_carson: "Carson County",
  us_tx_cass: "Cass County",
  us_tx_castro: "Castro County",
  us_tx_chambers: "Chambers County",
  us_tx_cherokee: "Cherokee County",
  us_tx_childress: "Childress County",
  us_tx_clay: "Clay County",
  us_tx_cochran: "Cochran County",
  us_tx_coke: "Coke County",
  us_tx_coleman: "Coleman County",
  us_tx_collin: "Collin County",
  us_tx_collingsworth: "Collingsworth County",
  us_tx_colorado: "Colorado County",
  us_tx_comal: "Comal County",
  us_tx_comanche: "Comanche County",
  us_tx_concho: "Concho County",
  us_tx_cooke: "Cooke County",
  us_tx_coryell: "Coryell County",
  us_tx_cottle: "Cottle County",
  us_tx_crane: "Crane County",
  us_tx_crockett: "Crockett County",
  us_tx_crosby: "Crosby County",
  us_tx_culberson: "Culberson County",
  us_tx_dallam: "Dallam County",
  us_tx_dallas: "Dallas County",
  us_tx_dawson: "Dawson County",
  us_tx_deaf_smith: "Deaf Smith County",
  us_tx_delta: "Delta County",
  us_tx_denton: "Denton County",
  us_tx_dewitt: "DeWitt County",
  us_tx_dickens: "Dickens County",
  us_tx_dimmit: "Dimmit County",
  us_tx_donley: "Donley County",
  us_tx_duval: "Duval County",
  us_tx_eastland: "Eastland County",
  us_tx_ector: "Ector County",
  us_tx_edwards: "Edwards County",
  us_tx_ellis: "Ellis County",
  us_tx_el_paso: "El Paso County",
  us_tx_erath: "Erath County",
  us_tx_falls: "Falls County",
  us_tx_fannin: "Fannin County",
  us_tx_fayette: "Fayette County",
  us_tx_fisher: "Fisher County",
  us_tx_floyd: "Floyd County",
  us_tx_foard: "Foard County",
  us_tx_fort_bend: "Fort Bend County",
  us_tx_franklin: "Franklin County",
  us_tx_freestone: "Freestone County",
  us_tx_frio: "Frio County",
  us_tx_gaines: "Gaines County",
  us_tx_galveston: "Galveston County",
  us_tx_garza: "Garza County",
  us_tx_gillespie: "Gillespie County",
  us_tx_glasscock: "Glasscock County",
  us_tx_goliad: "Goliad County",
  us_tx_gonzales: "Gonzales County",
  us_tx_gray: "Gray County",
  us_tx_grayson: "Grayson County",
  us_tx_gregg: "Gregg County",
  us_tx_grimes: "Grimes County",
  us_tx_guadalupe: "Guadalupe County",
  us_tx_hale: "Hale County",
  us_tx_hall: "Hall County",
  us_tx_hamilton: "Hamilton County",
  us_tx_hansford: "Hansford County",
  us_tx_hardeman: "Hardeman County",
  us_tx_hardin: "Hardin County",
  us_tx_harris: "Harris County",
  us_tx_harrison: "Harrison County",
  us_tx_hartley: "Hartley County",
  us_tx_haskell: "Haskell County",
  us_tx_hays: "Hays County",
  us_tx_hemphill: "Hemphill County",
  us_tx_henderson: "Henderson County",
  us_tx_hidalgo: "Hidalgo County",
  us_tx_hill: "Hill County",
  us_tx_hockley: "Hockley County",
  us_tx_hood: "Hood County",
  us_tx_hopkins: "Hopkins County",
  us_tx_houston: "Houston County",
  us_tx_howard: "Howard County",
  us_tx_hudspeth: "Hudspeth County",
  us_tx_hunt: "Hunt County",
  us_tx_hutchinson: "Hutchinson County",
  us_tx_irion: "Irion County",
  us_tx_jack: "Jack County",
  us_tx_jackson: "Jackson County",
  us_tx_jasper: "Jasper County",
  us_tx_jeff_davis: "Jeff Davis County",
  us_tx_jefferson: "Jefferson County",
  us_tx_jim_hogg: "Jim Hogg County",
  us_tx_jim_wells: "Jim Wells County",
  us_tx_johnson: "Johnson County",
  us_tx_jones: "Jones County",
  us_tx_karnes: "Karnes County",
  us_tx_kaufman: "Kaufman County",
  us_tx_kendall: "Kendall County",
  us_tx_kenedy: "Kenedy County",
  us_tx_kent: "Kent County",
  us_tx_kerr: "Kerr County",
  us_tx_kimble: "Kimble County",
  us_tx_king: "King County",
  us_tx_kinney: "Kinney County",
  us_tx_kleberg: "Kleberg County",
  us_tx_knox: "Knox County",
  us_tx_lamar: "Lamar County",
  us_tx_lamb: "Lamb County",
  us_tx_lampasas: "Lampasas County",
  us_tx_la_salle: "La Salle County",
  us_tx_lavaca: "Lavaca County",
  us_tx_lee: "Lee County",
  us_tx_leon: "Leon County",
  us_tx_liberty: "Liberty County",
  us_tx_limestone: "Limestone County",
  us_tx_lipscomb: "Lipscomb County",
  us_tx_live_oak: "Live Oak County",
  us_tx_llano: "Llano County",
  us_tx_loving: "Loving County",
  us_tx_lubbock: "Lubbock County",
  us_tx_lynn: "Lynn County",
  us_tx_mcculloch: "McCulloch County",
  us_tx_mclennan: "McLennan County",
  us_tx_mcmullen: "McMullen County",
  us_tx_madison: "Madison County",
  us_tx_marion: "Marion County",
  us_tx_martin: "Martin County",
  us_tx_mason: "Mason County",
  us_tx_matagorda: "Matagorda County",
  us_tx_maverick: "Maverick County",
  us_tx_medina: "Medina County",
  us_tx_menard: "Menard County",
  us_tx_midland: "Midland County",
  us_tx_milam: "Milam County",
  us_tx_mills: "Mills County",
  us_tx_mitchell: "Mitchell County",
  us_tx_montague: "Montague County",
  us_tx_montgomery: "Montgomery County",
  us_tx_moore: "Moore County",
  us_tx_morris: "Morris County",
  us_tx_motley: "Motley County",
  us_tx_nacogdoches: "Nacogdoches County",
  us_tx_navarro: "Navarro County",
  us_tx_newton: "Newton County",
  us_tx_nolan: "Nolan County",
  us_tx_nueces: "Nueces County",
  us_tx_ochiltree: "Ochiltree County",
  us_tx_oldham: "Oldham County",
  us_tx_orange: "Orange County",
  us_tx_palo_pinto: "Palo Pinto County",
  us_tx_panola: "Panola County",
  us_tx_parker: "Parker County",
  us_tx_parmer: "Parmer County",
  us_tx_pecos: "Pecos County",
  us_tx_polk: "Polk County",
  us_tx_potter: "Potter County",
  us_tx_presidio: "Presidio County",
  us_tx_rains: "Rains County",
  us_tx_randall: "Randall County",
  us_tx_reagan: "Reagan County",
  us_tx_real: "Real County",
  us_tx_red_river: "Red River County",
  us_tx_reeves: "Reeves County",
  us_tx_refugio: "Refugio County",
  us_tx_roberts: "Roberts County",
  us_tx_robertson: "Robertson County",
  us_tx_rockwall: "Rockwall County",
  us_tx_runnels: "Runnels County",
  us_tx_rusk: "Rusk County",
  us_tx_sabine: "Sabine County",
  us_tx_san_augustine: "San Augustine County",
  us_tx_san_jacinto: "San Jacinto County",
  us_tx_san_patricio: "San Patricio County",
  us_tx_san_saba: "San Saba County",
  us_tx_schleicher: "Schleicher County",
  us_tx_scurry: "Scurry County",
  us_tx_shackelford: "Shackelford County",
  us_tx_shelby: "Shelby County",
  us_tx_sherman: "Sherman County",
  us_tx_smith: "Smith County",
  us_tx_somervell: "Somervell County",
  us_tx_starr: "Starr County",
  us_tx_stephens: "Stephens County",
  us_tx_sterling: "Sterling County",
  us_tx_stonewall: "Stonewall County",
  us_tx_sutton: "Sutton County",
  us_tx_swisher: "Swisher County",
  us_tx_tarrant: "Tarrant County",
  us_tx_taylor: "Taylor County",
  us_tx_terrell: "Terrell County",
  us_tx_terry: "Terry County",
  us_tx_throckmorton: "Throckmorton County",
  us_tx_titus: "Titus County",
  us_tx_tom_green: "Tom Green County",
  us_tx_travis: "Travis County",
  us_tx_trinity: "Trinity County",
  us_tx_tyler: "Tyler County",
  us_tx_upshur: "Upshur County",
  us_tx_upton: "Upton County",
  us_tx_uvalde: "Uvalde County",
  us_tx_val_verde: "Val Verde County",
  us_tx_van_zandt: "Van Zandt County",
  us_tx_victoria: "Victoria County",
  us_tx_walker: "Walker County",
  us_tx_waller: "Waller County",
  us_tx_ward: "Ward County",
  us_tx_washington: "Washington County",
  us_tx_webb: "Webb County",
  us_tx_wharton: "Wharton County",
  us_tx_wheeler: "Wheeler County",
  us_tx_wichita: "Wichita County",
  us_tx_wilbarger: "Wilbarger County",
  us_tx_willacy: "Willacy County",
  us_tx_williamson: "Williamson County",
  us_tx_wilson: "Wilson County",
  us_tx_winkler: "Winkler County",
  us_tx_wise: "Wise County",
  us_tx_wood: "Wood County",
  us_tx_yoakum: "Yoakum County",
  us_tx_young: "Young County",
  us_tx_zapata: "Zapata County",
  us_tx_zavala: "Zavala County",
  us_ut_beaver: "Beaver County",
  us_ut_box_elder: "Box Elder County",
  us_ut_cache: "Cache County",
  us_ut_carbon: "Carbon County",
  us_ut_daggett: "Daggett County",
  us_ut_davis: "Davis County",
  us_ut_duchesne: "Duchesne County",
  us_ut_emery: "Emery County",
  us_ut_garfield: "Garfield County",
  us_ut_grand: "Grand County",
  us_ut_iron: "Iron County",
  us_ut_juab: "Juab County",
  us_ut_kane: "Kane County",
  us_ut_millard: "Millard County",
  us_ut_morgan: "Morgan County",
  us_ut_piute: "Piute County",
  us_ut_rich: "Rich County",
  us_ut_salt_lake: "Salt Lake County",
  us_ut_san_juan: "San Juan County",
  us_ut_sanpete: "Sanpete County",
  us_ut_sevier: "Sevier County",
  us_ut_summit: "Summit County",
  us_ut_tooele: "Tooele County",
  us_ut_uintah: "Uintah County",
  us_ut_utah: "Utah County",
  us_ut_wasatch: "Wasatch County",
  us_ut_washington: "Washington County",
  us_ut_wayne: "Wayne County",
  us_ut_weber: "Weber County",
  us_vt_addison: "Addison County",
  us_vt_bennington: "Bennington County",
  us_vt_caledonia: "Caledonia County",
  us_vt_chittenden: "Chittenden County",
  us_vt_essex: "Essex County",
  us_vt_franklin: "Franklin County",
  us_vt_grand_isle: "Grand Isle County",
  us_vt_lamoille: "Lamoille County",
  us_vt_orange: "Orange County",
  us_vt_orleans: "Orleans County",
  us_vt_rutland: "Rutland County",
  us_vt_washington: "Washington County",
  us_vt_windham: "Windham County",
  us_vt_windsor: "Windsor County",
  us_va_accomack: "Accomack County",
  us_va_albemarle: "Albemarle County",
  us_va_alleghany: "Alleghany County",
  us_va_amelia: "Amelia County",
  us_va_amherst: "Amherst County",
  us_va_appomattox: "Appomattox County",
  us_va_arlington: "Arlington County",
  us_va_augusta: "Augusta County",
  us_va_bath: "Bath County",
  us_va_bedford: "Bedford County",
  us_va_bland: "Bland County",
  us_va_botetourt: "Botetourt County",
  us_va_brunswick: "Brunswick County",
  us_va_buchanan: "Buchanan County",
  us_va_buckingham: "Buckingham County",
  us_va_campbell: "Campbell County",
  us_va_caroline: "Caroline County",
  us_va_carroll: "Carroll County",
  us_va_charles_city: "Charles City County",
  us_va_charlotte: "Charlotte County",
  us_va_chesterfield: "Chesterfield County",
  us_va_clarke: "Clarke County",
  us_va_craig: "Craig County",
  us_va_culpeper: "Culpeper County",
  us_va_cumberland: "Cumberland County",
  us_va_dickenson: "Dickenson County",
  us_va_dinwiddie: "Dinwiddie County",
  us_va_essex: "Essex County",
  us_va_fairfax: "Fairfax County",
  us_va_fauquier: "Fauquier County",
  us_va_floyd: "Floyd County",
  us_va_fluvanna: "Fluvanna County",
  us_va_franklin: "Franklin County",
  us_va_frederick: "Frederick County",
  us_va_giles: "Giles County",
  us_va_gloucester: "Gloucester County",
  us_va_goochland: "Goochland County",
  us_va_grayson: "Grayson County",
  us_va_greene: "Greene County",
  us_va_greensville: "Greensville County",
  us_va_halifax: "Halifax County",
  us_va_hanover: "Hanover County",
  us_va_henrico: "Henrico County",
  us_va_henry: "Henry County",
  us_va_highland: "Highland County",
  us_va_isle_of_wight: "Isle of Wight County",
  us_va_james_city: "James City County",
  us_va_king_and_queen: "King and Queen County",
  us_va_king_george: "King George County",
  us_va_king_william: "King William County",
  us_va_lancaster: "Lancaster County",
  us_va_lee: "Lee County",
  us_va_loudoun: "Loudoun County",
  us_va_louisa: "Louisa County",
  us_va_lunenburg: "Lunenburg County",
  us_va_madison: "Madison County",
  us_va_mathews: "Mathews County",
  us_va_mecklenburg: "Mecklenburg County",
  us_va_middlesex: "Middlesex County",
  us_va_montgomery: "Montgomery County",
  us_va_nelson: "Nelson County",
  us_va_new_kent: "New Kent County",
  us_va_northampton: "Northampton County",
  us_va_northumberland: "Northumberland County",
  us_va_nottoway: "Nottoway County",
  us_va_orange: "Orange County",
  us_va_page: "Page County",
  us_va_patrick: "Patrick County",
  us_va_pittsylvania: "Pittsylvania County",
  us_va_powhatan: "Powhatan County",
  us_va_prince_edward: "Prince Edward County",
  us_va_prince_george: "Prince George County",
  us_va_prince_william: "Prince William County",
  us_va_pulaski: "Pulaski County",
  us_va_rappahannock: "Rappahannock County",
  us_va_richmond: "Richmond County",
  us_va_roanoke: "Roanoke County",
  us_va_rockbridge: "Rockbridge County",
  us_va_rockingham: "Rockingham County",
  us_va_russell: "Russell County",
  us_va_scott: "Scott County",
  us_va_shenandoah: "Shenandoah County",
  us_va_smyth: "Smyth County",
  us_va_southampton: "Southampton County",
  us_va_spotsylvania: "Spotsylvania County",
  us_va_stafford: "Stafford County",
  us_va_surry: "Surry County",
  us_va_sussex: "Sussex County",
  us_va_tazewell: "Tazewell County",
  us_va_warren: "Warren County",
  us_va_washington: "Washington County",
  us_va_westmoreland: "Westmoreland County",
  us_va_wise: "Wise County",
  us_va_wythe: "Wythe County",
  us_va_york: "York County",
  us_va_alexandria_city: "Alexandria city",
  us_va_bedford_city: "Bedford city",
  us_va_bristol_city: "Bristol city",
  us_va_buena_vista_city: "Buena Vista city",
  us_va_charlottesville_city: "Charlottesville city",
  us_va_chesapeake_city: "Chesapeake city",
  us_va_colonial_heights_city: "Colonial Heights city",
  us_va_covington_city: "Covington city",
  us_va_danville_city: "Danville city",
  us_va_emporia_city: "Emporia city",
  us_va_fairfax_city: "Fairfax city",
  us_va_falls_church_city: "Falls Church city",
  us_va_franklin_city: "Franklin city",
  us_va_fredericksburg_city: "Fredericksburg city",
  us_va_galax_city: "Galax city",
  us_va_hampton_city: "Hampton city",
  us_va_harrisonburg_city: "Harrisonburg city",
  us_va_hopewell_city: "Hopewell city",
  us_va_lexington_city: "Lexington city",
  us_va_lynchburg_city: "Lynchburg city",
  us_va_manassas_city: "Manassas city",
  us_va_manassas_park_city: "Manassas Park city",
  us_va_martinsville_city: "Martinsville city",
  us_va_newport_news_city: "Newport News city",
  us_va_norfolk_city: "Norfolk city",
  us_va_norton_city: "Norton city",
  us_va_petersburg_city: "Petersburg city",
  us_va_poquoson_city: "Poquoson city",
  us_va_portsmouth_city: "Portsmouth city",
  us_va_radford_city: "Radford city",
  us_va_richmond_city: "Richmond city",
  us_va_roanoke_city: "Roanoke city",
  us_va_salem_city: "Salem city",
  us_va_staunton_city: "Staunton city",
  us_va_suffolk_city: "Suffolk city",
  us_va_virginia_beach_city: "Virginia Beach city",
  us_va_waynesboro_city: "Waynesboro city",
  us_va_williamsburg_city: "Williamsburg city",
  us_va_winchester_city: "Winchester city",
  us_wa_adams: "Adams County",
  us_wa_asotin: "Asotin County",
  us_wa_benton: "Benton County",
  us_wa_chelan: "Chelan County",
  us_wa_clallam: "Clallam County",
  us_wa_clark: "Clark County",
  us_wa_columbia: "Columbia County",
  us_wa_cowlitz: "Cowlitz County",
  us_wa_douglas: "Douglas County",
  us_wa_ferry: "Ferry County",
  us_wa_franklin: "Franklin County",
  us_wa_garfield: "Garfield County",
  us_wa_grant: "Grant County",
  us_wa_grays_harbor: "Grays Harbor County",
  us_wa_island: "Island County",
  us_wa_jefferson: "Jefferson County",
  us_wa_king: "King County",
  us_wa_kitsap: "Kitsap County",
  us_wa_kittitas: "Kittitas County",
  us_wa_klickitat: "Klickitat County",
  us_wa_lewis: "Lewis County",
  us_wa_lincoln: "Lincoln County",
  us_wa_mason: "Mason County",
  us_wa_okanogan: "Okanogan County",
  us_wa_pacific: "Pacific County",
  us_wa_pend_oreille: "Pend Oreille County",
  us_wa_pierce: "Pierce County",
  us_wa_san_juan: "San Juan County",
  us_wa_skagit: "Skagit County",
  us_wa_skamania: "Skamania County",
  us_wa_snohomish: "Snohomish County",
  us_wa_spokane: "Spokane County",
  us_wa_stevens: "Stevens County",
  us_wa_thurston: "Thurston County",
  us_wa_wahkiakum: "Wahkiakum County",
  us_wa_walla_walla: "Walla Walla County",
  us_wa_whatcom: "Whatcom County",
  us_wa_whitman: "Whitman County",
  us_wa_yakima: "Yakima County",
  us_wv_barbour: "Barbour County",
  us_wv_berkeley: "Berkeley County",
  us_wv_boone: "Boone County",
  us_wv_braxton: "Braxton County",
  us_wv_brooke: "Brooke County",
  us_wv_cabell: "Cabell County",
  us_wv_calhoun: "Calhoun County",
  us_wv_clay: "Clay County",
  us_wv_doddridge: "Doddridge County",
  us_wv_fayette: "Fayette County",
  us_wv_gilmer: "Gilmer County",
  us_wv_grant: "Grant County",
  us_wv_greenbrier: "Greenbrier County",
  us_wv_hampshire: "Hampshire County",
  us_wv_hancock: "Hancock County",
  us_wv_hardy: "Hardy County",
  us_wv_harrison: "Harrison County",
  us_wv_jackson: "Jackson County",
  us_wv_jefferson: "Jefferson County",
  us_wv_kanawha: "Kanawha County",
  us_wv_lewis: "Lewis County",
  us_wv_lincoln: "Lincoln County",
  us_wv_logan: "Logan County",
  us_wv_mcdowell: "McDowell County",
  us_wv_marion: "Marion County",
  us_wv_marshall: "Marshall County",
  us_wv_mason: "Mason County",
  us_wv_mercer: "Mercer County",
  us_wv_mineral: "Mineral County",
  us_wv_mingo: "Mingo County",
  us_wv_monongalia: "Monongalia County",
  us_wv_monroe: "Monroe County",
  us_wv_morgan: "Morgan County",
  us_wv_nicholas: "Nicholas County",
  us_wv_ohio: "Ohio County",
  us_wv_pendleton: "Pendleton County",
  us_wv_pleasants: "Pleasants County",
  us_wv_pocahontas: "Pocahontas County",
  us_wv_preston: "Preston County",
  us_wv_putnam: "Putnam County",
  us_wv_raleigh: "Raleigh County",
  us_wv_randolph: "Randolph County",
  us_wv_ritchie: "Ritchie County",
  us_wv_roane: "Roane County",
  us_wv_summers: "Summers County",
  us_wv_taylor: "Taylor County",
  us_wv_tucker: "Tucker County",
  us_wv_tyler: "Tyler County",
  us_wv_upshur: "Upshur County",
  us_wv_wayne: "Wayne County",
  us_wv_webster: "Webster County",
  us_wv_wetzel: "Wetzel County",
  us_wv_wirt: "Wirt County",
  us_wv_wood: "Wood County",
  us_wv_wyoming: "Wyoming County",
  us_wi_adams: "Adams County",
  us_wi_ashland: "Ashland County",
  us_wi_barron: "Barron County",
  us_wi_bayfield: "Bayfield County",
  us_wi_brown: "Brown County",
  us_wi_buffalo: "Buffalo County",
  us_wi_burnett: "Burnett County",
  us_wi_calumet: "Calumet County",
  us_wi_chippewa: "Chippewa County",
  us_wi_clark: "Clark County",
  us_wi_columbia: "Columbia County",
  us_wi_crawford: "Crawford County",
  us_wi_dane: "Dane County",
  us_wi_dodge: "Dodge County",
  us_wi_door: "Door County",
  us_wi_douglas: "Douglas County",
  us_wi_dunn: "Dunn County",
  us_wi_eau_claire: "Eau Claire County",
  us_wi_florence: "Florence County",
  us_wi_fond_du_lac: "Fond du Lac County",
  us_wi_forest: "Forest County",
  us_wi_grant: "Grant County",
  us_wi_green: "Green County",
  us_wi_green_lake: "Green Lake County",
  us_wi_iowa: "Iowa County",
  us_wi_iron: "Iron County",
  us_wi_jackson: "Jackson County",
  us_wi_jefferson: "Jefferson County",
  us_wi_juneau: "Juneau County",
  us_wi_kenosha: "Kenosha County",
  us_wi_kewaunee: "Kewaunee County",
  us_wi_la_crosse: "La Crosse County",
  us_wi_lafayette: "Lafayette County",
  us_wi_langlade: "Langlade County",
  us_wi_lincoln: "Lincoln County",
  us_wi_manitowoc: "Manitowoc County",
  us_wi_marathon: "Marathon County",
  us_wi_marinette: "Marinette County",
  us_wi_marquette: "Marquette County",
  us_wi_menominee: "Menominee County",
  us_wi_milwaukee: "Milwaukee County",
  us_wi_monroe: "Monroe County",
  us_wi_oconto: "Oconto County",
  us_wi_oneida: "Oneida County",
  us_wi_outagamie: "Outagamie County",
  us_wi_ozaukee: "Ozaukee County",
  us_wi_pepin: "Pepin County",
  us_wi_pierce: "Pierce County",
  us_wi_polk: "Polk County",
  us_wi_portage: "Portage County",
  us_wi_price: "Price County",
  us_wi_racine: "Racine County",
  us_wi_richland: "Richland County",
  us_wi_rock: "Rock County",
  us_wi_rusk: "Rusk County",
  us_wi_st_croix: "St. Croix County",
  us_wi_sauk: "Sauk County",
  us_wi_sawyer: "Sawyer County",
  us_wi_shawano: "Shawano County",
  us_wi_sheboygan: "Sheboygan County",
  us_wi_taylor: "Taylor County",
  us_wi_trempealeau: "Trempealeau County",
  us_wi_vernon: "Vernon County",
  us_wi_vilas: "Vilas County",
  us_wi_walworth: "Walworth County",
  us_wi_washburn: "Washburn County",
  us_wi_washington: "Washington County",
  us_wi_waukesha: "Waukesha County",
  us_wi_waupaca: "Waupaca County",
  us_wi_waushara: "Waushara County",
  us_wi_winnebago: "Winnebago County",
  us_wi_wood: "Wood County",
  us_wy_albany: "Albany County",
  us_wy_big_horn: "Big Horn County",
  us_wy_campbell: "Campbell County",
  us_wy_carbon: "Carbon County",
  us_wy_converse: "Converse County",
  us_wy_crook: "Crook County",
  us_wy_fremont: "Fremont County",
  us_wy_goshen: "Goshen County",
  us_wy_hot_springs: "Hot Springs County",
  us_wy_johnson: "Johnson County",
  us_wy_laramie: "Laramie County",
  us_wy_lincoln: "Lincoln County",
  us_wy_natrona: "Natrona County",
  us_wy_niobrara: "Niobrara County",
  us_wy_park: "Park County",
  us_wy_platte: "Platte County",
  us_wy_sheridan: "Sheridan County",
  us_wy_sublette: "Sublette County",
  us_wy_sweetwater: "Sweetwater County",
  us_wy_teton: "Teton County",
  us_wy_uinta: "Uinta County",
  us_wy_washakie: "Washakie County",
  us_wy_weston: "Weston County",
  us_as_eastern_district: "Eastern District",
  us_as_manua_district: "Manu'a District",
  us_as_rose_island: "Rose Island",
  us_as_swains_island: "Swains Island",
  us_as_western_district: "Western District",
  us_gu_guam: "Guam",
  us_mp_northern_islands: "Northern Islands Municipality",
  us_mp_rota: "Rota Municipality",
  us_mp_saipan: "Saipan Municipality",
  us_mp_tinian: "Tinian Municipality",
  us_pr_adjuntas: "Adjuntas Municipio",
  us_pr_aguada: "Aguada Municipio",
  us_pr_aguadilla: "Aguadilla Municipio",
  us_pr_aguas_buenas: "Aguas Buenas Municipio",
  us_pr_aibonito: "Aibonito Municipio",
  us_pr_añasco: "Añasco Municipio",
  us_pr_arecibo: "Arecibo Municipio",
  us_pr_arroyo: "Arroyo Municipio",
  us_pr_barceloneta: "Barceloneta Municipio",
  us_pr_barranquitas: "Barranquitas Municipio",
  us_pr_bayamón: "Bayamón Municipio",
  us_pr_cabo_rojo: "Cabo Rojo Municipio",
  us_pr_caguas: "Caguas Municipio",
  us_pr_camuy: "Camuy Municipio",
  us_pr_canóvanas: "Canóvanas Municipio",
  us_pr_carolina: "Carolina Municipio",
  us_pr_cataño: "Cataño Municipio",
  us_pr_cayey: "Cayey Municipio",
  us_pr_ceiba: "Ceiba Municipio",
  us_pr_ciales: "Ciales Municipio",
  us_pr_cidra: "Cidra Municipio",
  us_pr_coamo: "Coamo Municipio",
  us_pr_comerío: "Comerío Municipio",
  us_pr_corozal: "Corozal Municipio",
  us_pr_culebra: "Culebra Municipio",
  us_pr_dorado: "Dorado Municipio",
  us_pr_fajardo: "Fajardo Municipio",
  us_pr_florida: "Florida Municipio",
  us_pr_guánica: "Guánica Municipio",
  us_pr_guayama: "Guayama Municipio",
  us_pr_guayanilla: "Guayanilla Municipio",
  us_pr_guaynabo: "Guaynabo Municipio",
  us_pr_gurabo: "Gurabo Municipio",
  us_pr_hatillo: "Hatillo Municipio",
  us_pr_hormigueros: "Hormigueros Municipio",
  us_pr_humacao: "Humacao Municipio",
  us_pr_isabela: "Isabela Municipio",
  us_pr_jayuya: "Jayuya Municipio",
  us_pr_juana_díaz: "Juana Díaz Municipio",
  us_pr_juncos: "Juncos Municipio",
  us_pr_lajas: "Lajas Municipio",
  us_pr_lares: "Lares Municipio",
  us_pr_las_marías: "Las Marías Municipio",
  us_pr_las_piedras: "Las Piedras Municipio",
  us_pr_loíza: "Loíza Municipio",
  us_pr_luquillo: "Luquillo Municipio",
  us_pr_manatí: "Manatí Municipio",
  us_pr_maricao: "Maricao Municipio",
  us_pr_maunabo: "Maunabo Municipio",
  us_pr_mayagüez: "Mayagüez Municipio",
  us_pr_moca: "Moca Municipio",
  us_pr_morovis: "Morovis Municipio",
  us_pr_naguabo: "Naguabo Municipio",
  us_pr_naranjito: "Naranjito Municipio",
  us_pr_orocovis: "Orocovis Municipio",
  us_pr_patillas: "Patillas Municipio",
  us_pr_peñuelas: "Peñuelas Municipio",
  us_pr_ponce: "Ponce Municipio",
  us_pr_quebradillas: "Quebradillas Municipio",
  us_pr_rincón: "Rincón Municipio",
  us_pr_río_grande: "Río Grande Municipio",
  us_pr_sabana_grande: "Sabana Grande Municipio",
  us_pr_salinas: "Salinas Municipio",
  us_pr_san_germán: "San Germán Municipio",
  us_pr_san_juan: "San Juan Municipio",
  us_pr_san_lorenzo: "San Lorenzo Municipio",
  us_pr_san_sebastián: "San Sebastián Municipio",
  us_pr_santa_isabel: "Santa Isabel Municipio",
  us_pr_toa_alta: "Toa Alta Municipio",
  us_pr_toa_baja: "Toa Baja Municipio",
  us_pr_trujillo_alto: "Trujillo Alto Municipio",
  us_pr_utuado: "Utuado Municipio",
  us_pr_vega_alta: "Vega Alta Municipio",
  us_pr_vega_baja: "Vega Baja Municipio",
  us_pr_vieques: "Vieques Municipio",
  us_pr_villalba: "Villalba Municipio",
  us_pr_yabucoa: "Yabucoa Municipio",
  us_pr_yauco: "Yauco Municipio",
  us_um_midway_islands: "Midway Islands",
  us_vi_st_croix_island: "St. Croix Island",
  us_vi_st_john_island: "St. John Island",
  us_vi_st_thomas_island: "St. Thomas Island",
  us_al_al_unknown: "AL Unknown",
  us_ak_ak_unknown: "AK Unknown",
  us_az_az_unknown: "AZ Unknown",
  us_ar_ar_unknown: "AR Unknown",
  us_ca_ca_unknown: "CA Unknown",
  us_co_co_unknown: "CO Unknown",
  us_ct_ct_unknown: "CT Unknown",
  us_de_de_unknown: "DE Unknown",
  us_dc_dc_unknown: "DC Unknown",
  us_fl_fl_unknown: "FL Unknown",
  us_ga_ga_unknown: "GA Unknown",
  us_hi_hi_unknown: "HI Unknown",
  us_id_id_unknown: "ID Unknown",
  us_il_il_unknown: "IL Unknown",
  us_in_in_unknown: "IN Unknown",
  us_ia_ia_unknown: "IA Unknown",
  us_ks_ks_unknown: "KS Unknown",
  us_ky_ky_unknown: "KY Unknown",
  us_la_la_unknown: "LA Unknown",
  us_me_me_unknown: "ME Unknown",
  us_md_md_unknown: "MD Unknown",
  us_ma_ma_unknown: "MA Unknown",
  us_mi_mi_unknown: "MI Unknown",
  us_mn_mn_unknown: "MN Unknown",
  us_ms_ms_unknown: "MS Unknown",
  us_mo_mo_unknown: "MO Unknown",
  us_mt_mt_unknown: "MT Unknown",
  us_ne_ne_unknown: "NE Unknown",
  us_nv_nv_unknown: "NV Unknown",
  us_nh_nh_unknown: "NH Unknown",
  us_nj_nj_unknown: "NJ Unknown",
  us_nm_nm_unknown: "NM Unknown",
  us_ny_ny_unknown: "NY Unknown",
  us_nc_nc_unknown: "NC Unknown",
  us_nd_nd_unknown: "ND Unknown",
  us_oh_oh_unknown: "OH Unknown",
  us_ok_ok_unknown: "OK Unknown",
  us_or_or_unknown: "OR Unknown",
  us_pa_pa_unknown: "PA Unknown",
  us_ri_ri_unknown: "RI Unknown",
  us_sc_sc_unknown: "SC Unknown",
  us_sd_sd_unknown: "SD Unknown",
  us_tn_tn_unknown: "TN Unknown",
  us_tx_tx_unknown: "TX Unknown",
  us_ut_ut_unknown: "UT Unknown",
  us_vt_vt_unknown: "VT Unknown",
  us_va_va_unknown: "VA Unknown",
  us_wa_wa_unknown: "WA Unknown",
  us_wv_wv_unknown: "WV Unknown",
  us_wi_wi_unknown: "WI Unknown",
  us_wy_wy_unknown: "WY Unknown",
  us_as_as_unknown: "AS Unknown",
  us_gu_gu_unknown: "GU Unknown",
  us_mp_mp_unknown: "MP Unknown",
  us_pr_pr_unknown: "PR Unknown",
  us_um_um_unknown: "UM Unknown",
  us_vi_vi_unknown: "VI Unknown",
} as const;

export type FipsCountyCodeKey = keyof typeof FipsCountyCodes;
